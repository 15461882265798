import API from "../utils/API";
import React from "react";

export const TRANSFER_LOADING = 'TRANSFER_LOADING';
function loading () {
    return {type: TRANSFER_LOADING};
}


export const MY_TRANSFER_POST_SUCCESS = 'MY_TRANSFER_POST_SUCCESS';
function myOrderSuccess () {
    return {type: MY_TRANSFER_POST_SUCCESS};
}

export const MY_TRANSFER_POST_TRANSFER_SUCCESS = 'MY_TRANSFER_POST_TRANSFER_SUCCESS';
function myTransferSuccessList () {
    return {type: MY_TRANSFER_POST_TRANSFER_SUCCESS};
}

export const POPULATE_TRANSFER_LIST = 'POPULATE_TRANSFER_LIST';
function populateTransferList (data) {
    return {type: POPULATE_TRANSFER_LIST, data};
}

export const TRANSFER_ERROR = 'TRANSFER_ERROR';
export function transferError (statusText) {
    return {type: TRANSFER_ERROR, statusText};
}

export const TRANSFER_REFRESH_URL_CLEAR = 'TRANSFER_REFRESH_URL_CLEAR';
export function TransferRefreshUrlClear () {
    return {type: TRANSFER_REFRESH_URL_CLEAR};
}

export function performTransferStatusPost( fields ) {
    return dispatch => {
        dispatch(loading());
        API.post('transfers_actions', fields)
            .then(res => {
                dispatch(myTransferSuccessList());
            })
            .catch((error) => {
                // TODO Hadle error
            })
    }
}

export function performTransferGet( ) {
    return dispatch => {
        dispatch(loading());
        API.get('transfers_actions')
            .then(res => {
                console.log(res.data.transfers)
                dispatch(populateTransferList(res.data.transfers));
            })
            .catch((error) => {
                // TODO Hadle error
            })
    }
}
