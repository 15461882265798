import {LOADING, REGISTER, REG_ERROR} from '../actions/Registration';

const initialState = {
    email: '',
    username: '',
    password: '',
    status: 'READY',
    statusText: ''
}

function registrationReducer(state = initialState, action) {
    switch (action.type) {
        case 'REGISTER': {
            return Object.assign( {}, state,  {status: 'SUCCESS', statusText: action.statusText });
        }
        case 'REG_LOADING': {
            return Object.assign( {}, state,  {status: 'LOADING'});
        }
        case 'REG_ERROR': {
            return Object.assign( {}, state,  {status: 'ERROR', statusText: action.statusText });
        }
        default: {
            return state;
        }
    }
}

export default registrationReducer;