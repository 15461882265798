import {LOGIN_SUCCESS, LOGIN_LOADING, LOGIN_ERROR} from '../actions/Login';
import {getUserRoleFromToken} from '../utils/Utils';

const token = localStorage.getItem('mapnbag-api-token') === 'null' ?  null : localStorage.getItem('mapnbag-api-token');
const refreshToken = localStorage.getItem('mapnbag-api-refresh-token') === 'null' ?  null : localStorage.getItem('mapnbag-api-refresh-token');

const initialState = {
    token: token,
    refreshToken: refreshToken,
    role: getUserRoleFromToken(token),
    login: {
        status: 'READY',
        statusText: ''
    }
};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_SUCCESS': {
            return Object.assign({}, state, {token: action.data.token, refreshToken: action.data.refresh_token, role: getUserRoleFromToken(action.data.token), touristProperty: action.data.touristProperty, login: {status: 'READY'}});
           // return Object.assign({}, state, {token: '', refreshToken: '', login: {status: 'READY'}});
        }
        case 'LOGIN_REFRESH': {
            return Object.assign({}, state, {token: action.data.token, refreshToken: action.data.refresh_token, login: {status: 'READY'}});
        }
        case 'LOGIN_LOADING': {
            return Object.assign({}, state, {login: {status: 'LOADING'}});
        }
        case 'LOGIN_ERROR': {
            return Object.assign({}, state, {login: {status: 'ERROR', statusText: action.statusText}});
        }
        case 'LOGOUT': {
            return Object.assign({}, state, {token: '', refreshToken: ''});
        }
        default: {
            return state;
        }
    }
}

export default userReducer;
