import API from "../utils/API";
import React from "react";
import { ApiDataError } from './ApiData';

export const PROPERTY_LOADING = 'PROPERTY_LOADING';
function loading () {
    return {type: PROPERTY_LOADING};
}

export const PROPERTIES_SUCCESS = 'PROPERTIES_SUCCESS';
function populatePropertiesData (data) {
    return {type: PROPERTIES_SUCCESS, data};
}
export const PROPERTY_SUCCESS = 'PROPERTY_SUCCESS';
function populatePropertyData (data) {
    return {type: PROPERTY_SUCCESS, data};
}

export const PROPERTY_ERROR = 'PROPERTY_ERROR';
export function propertyError (statusText) {
    return {type: PROPERTY_ERROR, statusText};
}

export function performPropertiesRequest() {
    return dispatch => {
        dispatch(loading());
        API.get('properties')
            .then(res => {
                dispatch(populatePropertiesData(res.data['hydra:member']));
            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(propertyError(error.response.data.message));
            })
        
    }
}

export function performPropertyRequest(id) {
    return dispatch => {
        dispatch(loading());
        API.get('properties/'+id)
            .then(res => {
                dispatch(populatePropertyData(res.data));
            })
            .catch((error) => {
                // TODO Hadle error
            })

    }
}

export function performPropertyPut(url, fields, refreshUrl) {
    return dispatch => {
        dispatch(loading());
        API.put(url, fields)
            .then(res => {
                dispatch(performPropertiesRequest());
            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data.message));
            })
    }
}


