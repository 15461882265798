import React from 'react';
import { connect } from 'react-redux';
import { Container, } from 'semantic-ui-react';
import { performTransferGet, TransferRefreshUrlClear } from '../../actions/Transfer';
import TransferListUser from './TransferListUser';
import TransferListAdmin from './TransferListAdmin';
import TransferListTransfer from './TransferListTransfer';

class TransferList extends React.Component {
    state = {
        isLoading: true,
    };


    componentDidMount () {
        this.props.performTransferGet();
    }

    componentDidUpdate (prevProps) {
        if (prevProps.transfer.refreshUrl !== '/transfer-list' && this.props.transfer.refreshUrl === '/transfer-list') {
            this.props.transferRefreshUrlClear();
            this.props.performTransferGet();
        }
    }


    render () {
        return (
            <Container>
                {
                    {
                        'ROLE_ADMIN': <TransferListAdmin list={this.props.transfer.list}/>,
                        'ROLE_USER': <TransferListUser list={this.props.transfer.list}/>,
                        'ROLE_TRANSFER': <TransferListTransfer list={this.props.transfer.list}/>,
                    }[this.props.role]
                }
            </Container>
        );
    }
}

const mapStateTransfer = (state) => {
    return {
        role: state.userData.role,
        transfer: state.transfer
    };
};

const mapDispatchTransfer = (dispatch) => (
    {
        performTransferGet: () => (
            dispatch(performTransferGet())
        ),
        transferRefreshUrlClear: () => (
            dispatch(TransferRefreshUrlClear())
        )
    }
);

const TransferListDisplay = connect(
    mapStateTransfer,
    mapDispatchTransfer
)(TransferList);

export default TransferListDisplay;
