import React from 'react';
import { connect } from 'react-redux';
import { Button, Header, Input, Loader, Segment, Select, TextArea } from 'semantic-ui-react';
import { performApiDataPost, performApiDataPut, performApiDataRequest } from '../../actions/ApiData';
import { getUrlFromPath } from '../../utils/Utils';

class EditProperty extends React.Component {
    state = {
        isLoading: true,
        propertyData: {
            name: {
                title: 'Name of the property',
                data: '',
                type: 'text'
            },
            street: {
                title: 'Street and street number',
                data: '',
                type: 'text'
            },
            zip: {
                title: 'Zip',
                data: '',
                type: 'number'
            },
            description: {
                title: 'Description',
                data: '',
                type: 'textarea'
            },
            town: {
                title: 'Town or district',
                data: '',
                type: 'select',
                options: []
            },
        },
        propertyId: '',
        new: !getUrlFromPath(this.props.location.pathname, 2),
        valid: true,
        propId: getUrlFromPath(this.props.location.pathname, 2) || 0
    }

    setTownValuesToState = () => {
        const townsObject = this.props.apiData.data.Towns['hydra:member'];
        let propertyData = this.state.propertyData;
        let towns = [];
        Object.keys(townsObject).map((key) => {
            towns[key] = {
                key: '/towns/' + townsObject[key]['id'],
                value: '/towns/' + townsObject[key]['id'],
                text: townsObject[key]['name']
            };
        });
        propertyData.town.options = towns;
        const isLoading = false;
        this.setState({
            propertyData, isLoading
        });
    };

    setPropertyValuesToState = () => {
        let propertyData = this.state.propertyData;
        propertyData.name.data = this.props.apiData.data.Properties.name;
        propertyData.street.data = this.props.apiData.data.Properties.street;
        propertyData.zip.data = this.props.apiData.data.Properties.zip;
        propertyData.description.data = this.props.apiData.data.Properties.description;
        propertyData.town.data = this.props.apiData.data.Properties.town['@id'];
        this.setState({
            propertyData
        });
    };

    componentDidMount (prevProps) {

        if (typeof this.props.apiData.data.Properties !== 'undefined' && typeof this.props.apiData.data.Towns !== 'undefined') {
            if (!this.state.new) {
                this.setPropertyValuesToState();
            }
            this.setTownValuesToState();
        }

        if (this.state.new) {
            this.props.performTownRequest('towns/');
        } else {
            this.props.performPropertyRequest('properties/'+this.state.propId);
        }


        if (typeof this.props.apiData.data.Towns !== 'undefined') {
            this.setState({ isLoading: false });
        }
    };


    componentDidUpdate (prevProps) {
        if (!prevProps.apiData.data.Towns && this.props.apiData.data.Towns) {
            this.setTownValuesToState();
        }

        if(prevProps.apiData.refreshUrl !== 'properties' && this.props.apiData.refreshUrl === 'properties') {
            this.props.history.push('/properties');
        }

        if (!prevProps.apiData.data.Properties && this.props.apiData.data.Properties) {
            this.setPropertyValuesToState();
            this.props.performTownRequest('towns/');
        }
    }

    onChange = (e, data) => {
        let propertyData = this.state.propertyData;
        console.log(propertyData)
        let value = e.target.value;
        let key = e.target.name;
        if (data.type === 'number') {
            value = parseInt(value);
        } else if (data.type === 'select') {
            value = data.value;
            key = data.name;
        } else if(data.as ==='textarea') {
            value = data.value;
            key = data.name;
        }
        if (!value) {
            this.setState({ valid: false });
        }
        propertyData[key].data = value;
        console.log(propertyData)
        this.setState({
            propertyData
        });
    };

    saveProperty = (key, value) => {
        console.log('save')
        const propertyData = this.state.propertyData;
        let fields = {};
        let valid = true;
        Object.keys(propertyData).map((key) => {
            fields[key] = propertyData[key]['data'];
            if (!fields[key] && (key === 'name' || key === 'street' || key === 'zip') ) {
                this.setState({ valid: false });
                valid = false;
            }
        });

        if (valid) {
            if (this.state.new) {
                console.log(fields);
                this.props.performApiDataPost('set_property', fields, 'properties');
            } else {
                console.log(fields)
                console.log('old save');
                this.props.performPropertySave('properties/' + value.value, fields, 'properties/' + value.value);
            }
        } else {
            console.log('not valid');
        }
    };

    render () {
        const propertyId = this.state.propId;
        const property = this.state.propertyData;
        return (
            <div className="edit-restaurant">
                <Header as="h1" content="Edit property data" textAlign="center"/>
                <Segment.Group>
                    {
                        this.state.isLoading && this.state.propertyData.town.options ? (<Loader active/>) : (
                            Object.keys(property).map((key) => (
                                <Segment key={key}>
                                    <div><Header as="h3" content={property[key]['title']}/></div>
                                    <div>
                                        {
                                            {
                                                'select': <Select compact
                                                                  name={key}
                                                                  type={property[key]['type']}
                                                                  options={property[key]['options']}
                                                                  value={property[key]['data']}
                                                                  onChange={this.onChange}/>,
                                                'textarea': <TextArea name={key}
                                                                      value={property[key]['data']}
                                                                      onChange={this.onChange}/>
                                            }[property[key]['type']] || <Input name={key}
                                                                                 value={property[key]['data']}
                                                                                 type={property[key]['type']}
                                                                                 onChange={this.onChange}/>
                                        }
                                    </div>
                                </Segment>
                            ))
                        )
                    }
                </Segment.Group>
                {
                    this.state.isLoading ? (<div><Loader active/></div>) :
                        (<Button color="teal" size="big" value={propertyId} onClick={this.saveProperty}>Save</Button>)
                }
            </div>
        );
    }
}

const mapStateToList = (state) => {
    return {
        apiData: state.apiData,
    };
};

const mapDispatchList = (dispatch) => (
    {
        performTownRequest: (url) => (
            dispatch(performApiDataRequest(url))
        ),
        performPropertyRequest: (url) => (
            dispatch(performApiDataRequest(url))
        ),
        performApiDataPost: (url, fields, refreshUrl) => (
            dispatch(performApiDataPost(url, fields, refreshUrl))
        ),
        performPropertySave: (url, fields, refreshUrl) => (
            dispatch(performApiDataPut(url, fields, refreshUrl))
        ),
    }
);

const PropertyEditDisplay = connect(
    mapStateToList,
    mapDispatchList
)(EditProperty);

export default PropertyEditDisplay;
