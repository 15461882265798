import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {loginRefresh, logout} from "../actions/Login";
import store from "./Store";
import Constants from './Constants';

const API = axios.create({
    baseURL: Constants.baseURL,
    headers: {
        'Content-Type': 'application/json',
//        'Authorization' : 'Bearer: ' + store.getState().userData.token,
    },
});

const refreshAuthLogic = failedRequest => API.get('refresh_token?refresh_token='+store.getState().userData.refreshToken)
    .then(tokenRefreshResponse => {
        console.log("401");
        store.dispatch(loginRefresh(tokenRefreshResponse.data));
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
        return Promise.resolve();
}).catch(function (error) {
        store.dispatch(logout());
        return Promise.resolve();
    });
if(store.getState().userData.refreshToken !== null) {
    createAuthRefreshInterceptor(API, refreshAuthLogic);
}

export default API;
