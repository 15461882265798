import API from "../utils/API";

export const PASS_LOADING = 'PASS_LOADING';
function loading () {
    return {type: PASS_LOADING};
}

export const FORGOTPASSWORD = 'FORGOTPASSWORD';
function forgotPassword (statusText) {
    return {type: FORGOTPASSWORD, statusText};
}

export const PASS_ERROR = 'PASS_ERROR';
export function passError (statusText) {
    return {type: PASS_ERROR, statusText};
}

export function performForgotPassword(fields) {
    return dispatch => {

        dispatch(loading());

        API.post('register', fields)
            .then(res => {
                dispatch(forgotPassword(res.data));
            })
            .catch((error) => {
                dispatch(passError(error.response.data));
            })
    }
}
