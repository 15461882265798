import {PASS_LOADING, FORGOTPASSWORD, PASS_ERROR} from '../actions/ForgotPassword';

const initialState = {
    email: '',
    username: '',
    password: '',
    status: 'READY',
    statusText: ''
}

function forgotPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case 'FORGOTPASSWORD': {
            return Object.assign( {}, state,  {status: 'SUCCESS', statusText: action.statusText });
        }
        case 'PASS_LOADING': {
            return Object.assign( {}, state,  {status: 'LOADING'});
        }
        case 'PASS_ERROR': {
            return Object.assign( {}, state,  {status: 'ERROR', statusText: action.statusText });
        }
        default: {
            return state;
        }
    }
}

export default forgotPasswordReducer;
