import React from 'react';
import { connect } from 'react-redux';
import { Container, } from 'semantic-ui-react';
import { performOrderGet, OrderRefreshUrlClear } from '../../actions/Order';
import OrderListUser from './OrderListUser';
import OrderListAdmin from './OrderListAdmin';
import OrderListRestaurant from './OrderListRestaurant';
import OrderListRenter from './OrderListRenter';

class OrderList extends React.Component {
    state = {
        isLoading: true,
    };


    componentDidMount () {
        this.props.performOrderGet();
    }

    componentDidUpdate (prevProps) {
        if (prevProps.order.refreshUrl !== '/order_list' && this.props.order.refreshUrl === '/order_list') {
            this.props.OrderRefreshUrlClear();
            this.props.performOrderGet();
        }
    }


    render () {
        return (
            <Container>
                {
                    {
                        'ROLE_ADMIN': <OrderListAdmin list={this.props.order.order_list}/>,
                        'ROLE_USER': <OrderListUser list={this.props.order.order_list}/>,
                        'ROLE_RESTAURANT': <OrderListRestaurant list={this.props.order.order_list}/>,
                        'ROLE_RENTER': <OrderListRenter history={this.props.history} list={this.props.order.order_list}/>
                    }[this.props.role]
                }
            </Container>
        );
    }
}

const mapStateRestaurant = (state) => {
    return {
        role: state.userData.role,
        order: state.order
    };
};

const mapDispatchRestaurant = (dispatch) => (
    {
        performOrderGet: () => (
            dispatch(performOrderGet())
        ),
        OrderRefreshUrlClear: () => (
            dispatch(OrderRefreshUrlClear())
        )
    }
);

const OrderListDisplay = connect(
    mapStateRestaurant,
    mapDispatchRestaurant
)(OrderList);

export default OrderListDisplay;
