import React from 'react';
import { Button, Grid, Header, Table } from 'semantic-ui-react';
import { performTransferStatusPost } from '../../actions/Transfer';
import { connect } from 'react-redux';

class TransferListAdmin extends React.Component {

    setStatus = (status) => {
        if(status === 0) {
            return 'warning';
        } else if(status === 1) {
            return 'positive';
        }
        else if(status === -1) {
            return 'negative';
        }
    }

    postListItem = (event) => {
        const fields = {
            'id' : event.target.value,
            'status': 3
        }
        this.props.performTransferListPost(fields)
    };


    render () {
        const list = this.props.list?? [];
        return (
            <Grid>
                <Header as='h1'
                        content={'Order list'}
                        textAlign='center'/>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Transfer company</Table.HeaderCell>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            Object.keys(list).map((key) => (
                                <Table.Row>
                                    <Table.Cell>
                                        {list[key]['transferCompany']['username']}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {list[key]['user']['username']}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit"
                                        }).format(new Date(list[key]['transferDate']['date']))}
                                    </Table.Cell>
                                    <Table.Cell className={this.setStatus(list[key]['status'])}>
                                        {list[key][['statusText']]}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            list[key]['status'] === 2 ? (
                                                <Button value={list[key]['id']} color='green' onClick={this.postListItem}>
                                                    Pay this commission
                                                </Button>
                                            ) :  (
                                                ''
                                            )
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </Grid>
        );
    }
}

const mapStateTransferList= (state) => {
    return {
        order: state.transfer
    };
};

const mapDispatchTransferList = (dispatch) => (
    {
        performTransferListPost: (fields) => (
            dispatch(performTransferStatusPost(fields))
        )
    }
);

const TransferListAdminDisplay = connect(
    mapStateTransferList,
    mapDispatchTransferList
)(TransferListAdmin);

export default TransferListAdminDisplay;
