import {RESTAURANT_SUCCESS, RESTAURANTS_SUCCESS, RESTAURANT_IMAGE_SUCCESS,  RESTAURANT_LOADING, RESTAURANT_ERROR} from '../actions/Restaurant';

const initialState = {
    isLoading: false,
    list: {
    },
    data: {},
    dishCategories: {},
    reviews: {},
    images: {}
};

function RestaurantReducer(state = initialState, action) {
    switch (action.type) {
        case 'RESTAURANTS_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, list: action.data} );
        }
        case 'RESTAURANT_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, data: action.data, dishCategories: action.data.dishCategories, reviews: action.data.reviewsOfRestaurant} );
        }
        case 'RESTAURANT_IMAGE_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, images: action.data} );
        }
        case 'RESTAURANT_LOADING': {
            return Object.assign({}, state, {isLoading: true});
        }
        case 'RESTAURANT_ERROR': {
            return Object.assign({}, state, {});
        }
        default: {
            return state;
        }
    }
}

export default RestaurantReducer;
