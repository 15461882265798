/* eslint-disable no-shadow */
import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";


const ProtectedRoute = ({ component, role, allowedRoles, ...rest }) => (

    <Route {...rest} render={(props) => (
        allowedRoles.includes(role) ? (
            React.createElement(component, props)
        ) : (
            <Redirect to={{
                pathname: '/restaurants',
            }} />
        )
    )} />
);
const mapStateToProtectedRoute = (state) => {
    return {
        role:state.userData.role,
    };
};

const ProtectedRouteDisplay = connect(
    mapStateToProtectedRoute
)(ProtectedRoute);


export default ProtectedRouteDisplay;
