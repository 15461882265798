import API from "../utils/API";
import React from "react";

export const ORDER_LOADING = 'ORDER_LOADING';
function loading () {
    return {type: ORDER_LOADING};
}


export const MY_ORDER_POST_SUCCESS = 'MY_ORDER_POST_SUCCESS';
function myOrderSuccess () {
    return {type: MY_ORDER_POST_SUCCESS};
}

export const MY_ORDER_POST_RENTER_SUCCESS = 'MY_ORDER_POST_RENTER_SUCCESS';
function myOrderSuccessList () {
    return {type: MY_ORDER_POST_RENTER_SUCCESS};
}

export const POPULATE_ORDER_LIST = 'POPULATE_ORDER_LIST';
function populateOrderList (data) {
    return {type: POPULATE_ORDER_LIST, data};
}

export const ORDER_ERROR = 'ORDER_ERROR';
export function ordertError (statusText) {
    return {type: ORDER_ERROR, statusText};
}

export const ORDER_REFRESH_URL_CLEAR = 'ORDER_REFRESH_URL_CLEAR';
export function OrderRefreshUrlClear () {
    return {type: ORDER_REFRESH_URL_CLEAR};
}

export function performOrderPut( fields ) {
    return dispatch => {
        dispatch(loading());
        API.put('rest_orders', fields)
            .then(res => {
                dispatch(myOrderSuccess());
            })
            .catch((error) => {
                // TODO Hadle error
            })
    }
}

export function performOrderStatusPost( fields ) {
    return dispatch => {
        dispatch(loading());
        API.post('rest_orders', fields)
            .then(res => {
                dispatch(myOrderSuccessList());
            })
            .catch((error) => {
                // TODO Hadle error
            })
    }
}

export function performOrderGet( ) {
    return dispatch => {
        dispatch(loading());
        API.get('rest_orders')
            .then(res => {
                console.log(res.data.orders)
                dispatch(populateOrderList(res.data.orders));
            })
            .catch((error) => {
                // TODO Hadle error
            })
    }
}
