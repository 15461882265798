

export function getUserRoleFromToken(jwtToken) {
    if (!jwtToken) {
        return '';
    }
    let jwtData = jwtToken.split('.')[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    return getUserRole(decodedJwtData.roles);
}

export function getUserRole(roleArray) {
    if(roleArray.includes('ROLE_ADMIN')) {
        return 'ROLE_ADMIN';
    }
    else if(roleArray.includes('ROLE_RESTAURANT')) {
        return 'ROLE_RESTAURANT';
    }
    else if(roleArray.includes('ROLE_RENTER')) {
        return 'ROLE_RENTER';
    }
    else if(roleArray.includes('ROLE_TRANSFER')) {
        return 'ROLE_TRANSFER';
    }
    else  {
        return 'ROLE_USER';
    }
}

export function getStatusText(statusCode) {
    if(statusCode === -1) {
        return 'blocked';
    } else if(statusCode === 0) {
        return 'not yet activated';
    } else if(statusCode === 1) {
        return 'active';
    } else {
        return '';
    }
}

export function getStatusArray() {
    const statusArray = [
        { key: -1, value: -1, text: 'blocked' },
        { key: 0, value: 0, text: 'not yet activated' },
        { key: 1, value: 1, text: 'active' },
    ];
    return statusArray;
}

export function getRolesArray() {
    const rolesArray = [
        { key: 'ROLE_ADMIN', value: 'ROLE_ADMIN', text: 'Admin' },
        { key: 'ROLE_USER', value: 'ROLE_USER', text: 'User' },
        { key: 'ROLE_RESTAURANT', value: 'ROLE_RESTAURANT', text: 'Restaurant' },
    ];
    return rolesArray;
}

export function getRandomNumber(max) {
    return 4;
    return Math.floor(Math.random() * Math.floor(max))+1;
}

export function getUrlFromPath(pathname, position) {
    const pathArray = pathname.split('/');
    return pathArray[position];
}

export function filterDishes(dishCategories) {
    Object.keys(dishCategories).map((key) => {
        Object.keys(dishCategories[key]['dishes']).map((keyy) => {
            if(dishCategories[key]['dishes'][keyy]['status'] !== 1) {
                delete dishCategories[key]['dishes'][keyy]
            }
        })
        if(dishCategories[key]['status'] !== 1) {
            delete dishCategories[key]
        }
    })
    return dishCategories
}
export function generateRandom(min = 0, max = 100) {

    // find diff
    let difference = max - min;

    // generate random number
    let rand = Math.random();

    // multiply with difference
    rand = Math.floor( rand * difference);

    // add with min value
    rand = rand + min;

    return rand;
}
export function calculateRating(reviews) {
    const random = generateRandom(0,9);
    if(!reviews || reviews.length == undefined) {
        return 4 + '.' + random;
    }
    if(reviews.length === 0) {
        return 4 + '.' + random;
    }

    let result = 0;
    reviews.forEach(element => {
        result = result + ( parseInt(element['overall']) + parseInt(element['food']) + parseInt(element['service']) + parseInt(element['ambience']) ) / 4;
    });

    result = result / reviews.length;

    return Math.round(result * 100) / 100;
}

