import API from "../utils/API";
import React from "react";

export const APIDATA_LOADING = 'APIDATA_LOADING';
function loading () {
    return {type: APIDATA_LOADING};
}

export const APIDATA_SUCCESS = 'APIDATA_SUCCESS';
function populateApiData (data) {
    return {type: APIDATA_SUCCESS, data};
}

export const APIDATA_ERROR = 'APIDATA_ERROR';
export function ApiDataError (statusText) {
    return {type: APIDATA_ERROR, statusText};
}

export const APIDATA_CLEAR_ERROR = 'APIDATA_CLEAR_ERROR';
export function ApiDataClearError () {
    return {type: APIDATA_CLEAR_ERROR};
}

export const SET_APIDATA_REFRESH_URL = 'SET_APIDATA_REFRESH_URL';
export function setApiDataRefreshUrl (url) {
    return {type: SET_APIDATA_REFRESH_URL, url};
}

export function performApiDataRequest(url) {
    return dispatch => {
        dispatch(loading());
        API.get(url)
            .then(res => {
                const key = res.data['@context'].replace('/contexts/','')
                const data = {
                    [key]: res.data
                };
                dispatch(populateApiData(data));
            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data.message));
            })
    }
}

export function performApiRawRequest(url) {
    return dispatch => {
        dispatch(loading());
        API.get(url)
            .then(res => {
                //console.log(res.data)
                dispatch(populateApiData(res.data));
            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data.message));
            })
    }
}


export function performApiDataPut(url, fields, refreshUrl) {
    return dispatch => {
        dispatch(loading());
        API.put(url, fields)
            .then(res => {
                dispatch(performApiDataRequest(refreshUrl));

            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data.message));
            })
    }
}
export function performApiDataPost(url, fields, refreshUrl) {
    return dispatch => {
        dispatch(loading());
        API.post(url, fields)
            .then(res => {
                console.log(res)
                console.log('success');
                if(url === 'set_restaurant' || url === 'set_property' || url === 'save_user_transfer') {
                    console.log('refresh')
                    dispatch(setApiDataRefreshUrl(refreshUrl));
                } else {
                    dispatch(performApiDataRequest(refreshUrl));
                }
            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data));
            })
    }
}

export function performApiDataDelete(url, refreshUrl) {
    return dispatch => {
        dispatch(loading());
        API.delete(url)
            .then(res => {
                dispatch(performApiDataRequest(refreshUrl));
            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data));
            })
    }
}

export function performImagePost(bodyFormData, refreshUrl) {
    return dispatch => {
        dispatch(loading());
        API.post('media_objects', bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                dispatch(performApiDataRequest(refreshUrl));
            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data.message));
            })
    }
}
