import {TRANSFER_LOADING, MY_TRANSFER_POST_SUCCESS, TRANSFER_ERROR, TRANSFER_REFRESH_URL_CLEAR, POPULATE_TRANSFER_LIST, MY_TRANSFER_POST_TRANSFER_SUCCESS} from '../actions/Transfer';

const initialState = {
    isLoading: false,
    orders: {
    },
    order_list: {},
    refreshUrl: ''
};

function TransferReducer(state = initialState, action) {
    switch (action.type) {
        case 'MY_TRANSFER_POST_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, refreshUrl: '/restaurants'} );
        }
        case 'MY_TRANSFER_POST_TRANSFER_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, refreshUrl: '/transfer-list'} );
        }
        case 'TRANSFER_LOADING': {
            return Object.assign({}, state, {isLoading: true});
        }
        case 'TRANSFER_ERROR': {
            return Object.assign({}, state, {});
        }
        case 'TRANSFER_REFRESH_URL_CLEAR': {
            return Object.assign({}, state, {});
        }
        case 'POPULATE_TRANSFER_LIST': {
            console.log(action)
            return Object.assign({}, state, {isLoading: false, list: action.data, refreshUrl: '' });
        }
        default: {
            return state;
        }
    }
}

export default TransferReducer;
