
const Constants = {
 // baseURL: 'https://localhost:8443/',
  baseURL: 'https://rentersdev.mapnbag.com/',


 // imageUrl: 'https://localhost:8443/'
 imageUrl: 'https://rentersdev.mapnbag.com/symfony/public/',
};


export default Constants;
