import React from 'react';
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {performForgotPassword, passError} from '../actions/ForgotPassword';


class ForgotPasswordForm extends React.Component {

    state = {
        fields: {
            email: '',
            type: 'password'
        },
        valid: false,
        errorMessage: '',

    };

    onChange = (e) => {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        this.setState({
            fields
        })
    };

    performRegistration = () => {
        const errorMessage = 'You have to fill the email field correct';
        if(!this.state.fields.email) {
            this.setState({valid: false, errorMessage: errorMessage});
            this.props.formSubmitError(errorMessage);
        } else {
            this.setState({valid: true, errorMessage: ''});
            this.props.onFormSubmit(this.state.fields);
        }
    };

    render() {
        const email = this.state.fields.email;
        return (
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Send a new password
                    </Header>
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input fluid icon='mail' name="email" iconPosition='left' placeholder='E-mail address'
                                        onChange={this.onChange}/>
                            {
                                {
                                    LOADING: <div className='ui active centered inline loader'/>,
                                    READY: (
                                        <Button color='teal' fluid size='large' onClick={this.performRegistration}>
                                            Send new password
                                        </Button>
                                    ),
                                    SUCCESS: (
                                        <Message>
                                            New password has been sent to {email}.
                                        </Message>
                                    ),
                                    ERROR: (<div>
                                            <Button color='teal' fluid size='large' onClick={this.performRegistration}>
                                                Send new password
                                            </Button>
                                            <Message negative>
                                                <p>{this.props.state.forgotPasswordData.statusText}</p>
                                            </Message>
                                        </div>
                                    ),
                                }[this.props.state.forgotPasswordData.status]
                            }
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToForgotPasswordForm = (state) => {
    return {
        state
    }
};

const mapDispatchForgotPasswordForm = (dispatch) => (
    {
        onFormSubmit: (fields) => (
            dispatch(performForgotPassword(fields))
        ),
        formSubmitError: (statusText) => (
            dispatch(passError(statusText))
        )
    }
);

const RegisterFormDisplay = connect(
    mapStateToForgotPasswordForm,
    mapDispatchForgotPasswordForm
)(ForgotPasswordForm);

export default RegisterFormDisplay

