import {RESTAURANT_SUCCESS, RESTAURANTS_SUCCESS, RESTAURANT_IMAGE_SUCCESS,  RESTAURANT_LOADING, RESTAURANT_ERROR} from '../actions/Restaurant';

const initialState = {
    isLoading: false,
    list: {
    },
    data: {},
};

function PropertyReducer(state = initialState, action) {
    switch (action.type) {
        case 'PROPERTIES_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, list: action.data } );
        }
        case 'PROPERTY_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, data: action.data } );
        }
        case 'PROPERTY_LOADING': {
            return Object.assign({}, state, {isLoading: true});
        }
        case 'PROPERTY_ERROR': {
            return Object.assign({}, state, {});
        }
        default: {
            return state;
        }
    }
}

export default PropertyReducer;
