import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {performLogin, logError} from '../actions/Login';
import '../css/style.css';

class LoginForm extends React.Component {

    state = {
        fields: {
            email: '',
            password: '',
        },
        valid: false,
        errorMessage: ''
    };

    onChange = (e) => {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        this.setState({
            fields
        })
    };

    performLogin = () => {
        const fields = this.state.fields;
        const errorMessage = 'You have to fill the fields correct';
        if (!fields.email || !fields.password) {
            this.setState({valid: false, errorMessage: errorMessage});
            this.props.formSubmitError(errorMessage);
        } else {
            this.setState({valid: true, errorMessage: ''});
            this.props.onFormSubmit(this.state.fields);

        }
    };

    render() {
        if (this.props.state.userData.token) {
            if(this.props.state.userData.role === 'ROLE_RENTER') {
                return (
                    <Redirect to="/properties"/>
                )
            } else if(this.props.state.userData.role === 'ROLE_TRANSFER') {
                return (
                    <Redirect to="/transfer-list"/>
                )
            }
            else if(this.props.state.userData.role === 'ROLE_RESTAURANT') {
                return (
                    <Redirect to="/order_list"/>
                )
            }
            else {
                return (
                    <Redirect to="/restaurants"/>
                )
            }
        } else {
            return (
                <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                    <Grid.Column className='login-box'>
                        <img src="/images/logo.png" alt="logo" />
                        <Header as='h2' color='teal' textAlign='center'>
                            Log-in to your account
                        </Header>
                        <Form size='large'>
                            <Segment stacked>
                                <Form.Input fluid icon='mail' iconPosition='left' placeholder='E-mail address'
                                            name='email'
                                            onChange={this.onChange}/>
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Password'
                                    type='password'
                                    name='password'
                                    onChange={this.onChange}
                                />
                                {
                                    {
                                        LOADING: <div className='ui active centered inline loader'/>,
                                        READY: (
                                            <Button color='teal' fluid size='large' onClick={this.performLogin}>
                                                Login
                                            </Button>
                                        ),
                                        SUCCESS: (
                                            <Message>
                                                Logged in
                                            </Message>
                                        ),
                                        ERROR: (<div>
                                                <Button color='teal' fluid size='large' onClick={this.performLogin}>
                                                    Login
                                                </Button>
                                                <Message negative>
                                                    <p>{this.props.state.userData.login.statusText}</p>
                                                </Message>
                                            </div>
                                        ),
                                    }[this.props.state.userData.login.status]
                                }
                            </Segment>
                        </Form>
                        <Message>
                            New to us? &nbsp;
                            <Link className='ui item' to='/registration'>
                                Sign Up
                            </Link>
                        </Message>
                        <Message>
                            Forgot your password? &nbsp;
                            <Link className='ui item' to='/forgot-password'>
                                Password assistance
                            </Link>
                        </Message>
                    </Grid.Column>
                </Grid>
            )
        }
    }
}

const mapStateToLoginForm = (state) => {
    return {
        state,
    };
};

const mapDispatchLoginForm = (dispatch) => (
    {
        onFormSubmit: (fields) => (
            dispatch(performLogin(fields))
        ),
        formSubmitError: (statusText) => (
            dispatch(logError(statusText))
        )
    }
);

const LoginFormDisplay = connect(
    mapStateToLoginForm,
    mapDispatchLoginForm
)(LoginForm);

export default LoginFormDisplay



