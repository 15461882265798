import React, { Component } from 'react'
import { Confirm } from 'semantic-ui-react'

class ErrorMessage extends Component {
    state = { open: this.props.error, errorMessage:this.props.errorMessage }


    componentDidUpdate(prevProps) {
        if(this.props.error && !this.state.open && !prevProps.error) {
            this.setState({ open: true })
        }
    }

    closeErrorMessage= () => {
        this.setState({ open: false, errorMessage: '' })
        this.props.closeErrorMessage()
    }

    render() {
        return (
            <div>
                <Confirm
                    open={this.state.open}
                    content={this.props.errorMessage}
                    onCancel={this.closeErrorMessage}
                    onConfirm={this.closeErrorMessage}
                />
            </div>
        )
    }
}

export default ErrorMessage
