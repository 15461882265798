import React from 'react';
import { Button, Confirm,  Grid, Header, Table } from 'semantic-ui-react';
import { performTransferStatusPost } from '../../actions/Transfer';
import { connect } from 'react-redux';

class TransferListUser extends React.Component {
    state = {
        confirmOpen: false,
        propertyToDelete: 0
    };

    setStatus = (status) => {
        if(status === 0) {
            return 'warning';
        } else if(status === 1) {
            return 'positive';
        }
        else if(status === -1) {
            return 'negative';
        }
    }

    handleConfirm = (key, value) => {
        this.setState({ confirmOpen: false });
        const fields = {
            'id' : this.state.propertyToDelete,
            'status': -1
        }
        this.props.performTransferListPost(fields)
    };

    handleCancel = () => this.setState({ confirmOpen: false });

    confirmOpen = (e) => {
        const propertyToDelete = e.target.value;
        this.setState({ propertyToDelete:propertyToDelete,  confirmOpen: true })
    }

    render () {
        const list = this.props.list?? [];
        const confirmOpen = this.state.confirmOpen;
        return (
            <Grid>
                <Header as='h1'
                        content={'My order list'}
                        textAlign='center'/>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Transfer company</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Transfer data</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            Object.keys(list).map((key) => (
                                <Table.Row>
                                    <Table.Cell>
                                        {list[key]['transferCompany']['username']}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {new Intl.DateTimeFormat("en-GB", {
                                        hour: 'numeric', minute: 'numeric',
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit"
                                    }).format(new Date(list[key]['transferDate']['date']))}
                                    </Table.Cell>
                                    <Table.Cell>
                                       Flight number: {list[key]['flightNumber']},
                                       Number of passangers: {list[key]['numberOfPassangers']}
                                    </Table.Cell>
                                    <Table.Cell className={this.setStatus(list[key]['status'])}>
                                        {list[key]['statusText']}
                                        {
                                            list[key]['status'] === 0 || list[key]['status'] === 1 ? (
                                                <>
                                                    <Button value={list[key]['id']}  color='red' className='decline-button'
                                                            onClick={this.confirmOpen}>Decline</Button>
                                                    <Confirm
                                                        open={confirmOpen}
                                                        onCancel={this.handleCancel}
                                                        onConfirm={this.handleConfirm}
                                                    />
                                                </>
                                            ) : ('')
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </Grid>
        );
    }
}

const mapStateTransferList= (state) => {
    return {
        order: state.transfer
    };
};

const mapDispatchTransferList = (dispatch) => (
    {
        performTransferListPost: (fields) => (
            dispatch(performTransferStatusPost(fields))
        )
    }
);

const TransferListUserDisplay = connect(
    mapStateTransferList,
    mapDispatchTransferList
)(TransferListUser);

export default TransferListUserDisplay;
