import API from "../utils/API";

export const REG_LOADING = 'REG_LOADING';
function loading () {
    return {type: REG_LOADING};
}

export const REGISTER = 'REGISTER';
function register (statusText) {
    return {type: REGISTER, statusText};
}

export const REG_ERROR = 'REG_ERROR';
export function regError (statusText) {
    return {type: REG_ERROR, statusText};
}

export function performRegistration(fields) {
    return dispatch => {

        dispatch(loading());

        API.post('register', fields)
            .then(res => {
                dispatch(register(res.data));
            })
            .catch((error) => {
                dispatch(regError(error.response.data));
            })
    }
}
