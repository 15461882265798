import React from 'react';
import { Button, Grid, Header, Table } from 'semantic-ui-react';
import { performOrderStatusPost } from '../../actions/Order';
import { connect } from 'react-redux';

class OrderListRenter extends React.Component {

    setStatus = (status) => {
        if(status === 0) {
            return 'warning';
        } else if(status === 1) {
            return 'positive';
        }
        else if(status === -1) {
            return 'negative';
        }
    }

    postListItem = (event) => {
        const fields = {
            'id' : event.target.value,
            'status': 2
        }
       this.props.performOrderListPost(fields)
    };


    render () {
        const list = this.props.list?? [];
        return (
            <Grid>
                <Header as='h1'
                        content={'Commission list'}
                        textAlign='center'/>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Restaurant</Table.HeaderCell>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Commission</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            Object.keys(list).map((key) => (
                                <Table.Row>
                                    <Table.Cell>
                                        {list[key]['restaurant']['name']}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {list[key]['user']['email']}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {list[key]['commission']}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit"
                                        }).format(new Date(list[key]['orderDate']['date']))}
                                    </Table.Cell>
                                    <Table.Cell className={this.setStatus(list[key]['status'])}>
                                        {list[key][['statusText']]}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            list[key]['status'] === 1 ? (
                                                <Button value={list[key]['id']} color='green' onClick={this.postListItem}>
                                                    Request commission
                                                </Button>
                                            ) :  (
                                                ''
                                            )
                                        }

                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </Grid>
        );
    }
}

const mapStateOrderList= (state) => {
    return {
        order: state.order
    };
};

const mapDispatchOrderList = (dispatch) => (
    {
        performOrderListPost: (fields) => (
            dispatch(performOrderStatusPost(fields))
        )
    }
);

const OrderListRenterDisplay = connect(
    mapStateOrderList,
    mapDispatchOrderList
)(OrderListRenter);

export default OrderListRenterDisplay;
