import React from 'react';
import { connect } from 'react-redux';
import { performApiDataDelete, performImagePost } from '../../actions/ApiData';
import { Button, Card, Header, Image, Loader, Segment } from 'semantic-ui-react';
import Constants from '../../utils/Constants';

class RestaurantImageEdit extends React.Component {
    state = {
        isLoading: false
    };

    openEditMenu = (key, value) => {
        const id = value.value;
        this.setState({ openedItems: this.state['openedItems'].concat(id) });
    };

    closeEditMenu = (key, value) => {
        const id = value.value;
        this.setState({ openedItems: this.state['openedItems'].filter(filter => filter !== id), isLoading: true });
    };

    performRestaurantImageDelete = (key, value) => {
        this.props.performRestaurantImageDelete(value.value, 'restaurants/'+this.props.apiData.data.Restaurants.id+'/images');
    };

    fileChange = (e) => {
        let bodyFormData = new FormData();
        bodyFormData.set('restid', this.props.apiData.data.Restaurants.id);
        bodyFormData.append('file', e.target.files[0]);
        this.props.performImagePost(bodyFormData, 'restaurants/'+this.props.apiData.data.Restaurants.id+'/images');
    };

    fileInputRef = React.createRef();

    render () {
        const images = this.props.apiData.data.MediaObject['hydra:member'];
        const loading = this.props.apiData.isLoading;
        return (
            <div className='edit-restaurant'>
                <Header as='h1'
                        content={'Images the menus of ' + this.props.apiData.data.Restaurants.name}
                        textAlign='center'/>
                {
                    loading ? (<Loader active/>) : (
                        <Card.Group>
                            {
                                Object.keys(images).map((key) => (
                                    <Card key={key}>
                                        <Image size='small' src={Constants.imageUrl + images[key]['contentUrl']}
                                               wrapped
                                               ui={false}/>
                                        <Card.Content extra>
                                            <Button value={images[key]['@id']}
                                                    onClick={this.performRestaurantImageDelete}
                                                    color='red'>Delete</Button>
                                        </Card.Content>
                                    </Card>
                                ))
                            }
                            <Card key='new_pic'>
                                <Card.Content extra>
                                    <Button
                                        content="Choose File"
                                        labelPosition="left"
                                        icon="file"
                                        color='green'
                                        onClick={() => this.fileInputRef.current.click()}
                                    />
                                    <input
                                        ref={this.fileInputRef}
                                        type="file"
                                        hidden
                                        onChange={this.fileChange}
                                    />
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    )}
            </div>
        );
    }
}

const mapStateRestaurantImageEdit = (state) => {
    return {
        apiData: state.apiData,
        role: state.userData.role
    };
};

const mapDispatchRestaurantImageEdit = (dispatch) => {
    return {
        performRestaurantImageDelete: (url, refreshUrl) => (
            dispatch(performApiDataDelete(url, refreshUrl))
        ),
        performImagePost: (bodyFormData, refreshUrl) => (
            dispatch(performImagePost(bodyFormData, refreshUrl))
        )
    };
};

const RestaurantImageEditDisplay = connect(
    mapStateRestaurantImageEdit,
    mapDispatchRestaurantImageEdit
)(RestaurantImageEdit);

export default RestaurantImageEditDisplay;
