import React from 'react';
import { Card, Confirm, Grid, Container, Loader, Button, Image } from 'semantic-ui-react';
import { performRestaurantsRequest, performRestaurantPut, performRestaurantsPreviewRequest } from '../../actions/Restaurant';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Constants from '../../utils/Constants';
import { calculateRating, generateRandom } from '../../utils/Utils';
import { getUrlFromPath } from '../../utils/Utils';
import ErrorMessage from '../General/ErrorMessage';

class RestaurantListWrapped extends React.Component {
    render () {
        const pathname = getUrlFromPath(this.props.location.pathname, 2);
        return (
            <Container className={'list-width'}>
                <Grid>
                    <ListDisplay pathname={pathname}/>
                </Grid>
            </Container>
        );
    }
}

class List extends React.Component {
    state = {
        confirmOpen: false,
        message: false
    };
    componentDidMount () {
        if(this.props.pathname)
        {
            if(this.props.pathname === 'paid') {
                this.setState({ message: true });
                this.props.performRestaurantsRequest();
            } else {
                this.props.performRestaurantsPreviewRequest( { id: this.props.pathname });
            }
        } else
        {
            this.props.performRestaurantsRequest();
        }
    };

    handleConfirm = (key, value) => {
        this.setState({ confirmOpen: false });
        this.props.performRestaurantSave('restaurants/' + value.value, { status: 0 }, 'restaurants/');
    };
    handleCancel = () => this.setState({ confirmOpen: false });

    confirmOpen = () => this.setState({ confirmOpen: true });

    confirmMessage = () => {
        this.setState({ message: false });
    };

    render () {
        const list = this.props.restaurantData.list;
        const confirmOpen = this.state.confirmOpen;
        const { message } = this.state;
        return (
            <Container textAlign='center'>
                <ErrorMessage error={message}
                              errorMessage="Order has been processed"
                              closeErrorMessage={this.confirmMessage}/>
                {
                    this.props.restaurantData.isLoading ? (<Loader active/>) : ''
                }
                <Card.Group itemsPerRow='5'>
                    {
                        Object.keys(list).map((key) => (
                            <Card key={key}>
                                <Image as={Link} to={`/restaurant/${list[key]['id']}`} src={Constants.imageUrl + list[key]['profilePic']}
                                       centered={true}
                                       size='medium'
                                       />
                                <Card.Content>
                                    <Card.Header>
                                        <div>
                                            {list[key]['name']}
                                        </div>
                                    </Card.Header>
                                    <Card.Description>
                                        <div>
                                            {`Avarage price ${list[key]['avarage_price']} Ft`}
                                        </div>
                                    </Card.Description>
                                    <Card.Description>
                                        {/* <div className='review'>
                                            Rating: {calculateRating(list[key]['reviewsOfRestaurant'])} from { list[key]['reviewsOfRestaurant'] && list[key]['reviewsOfRestaurant'].length !== 0 ? list[key]['reviewsOfRestaurant'].length : generateRandom(10,150)} reviews
                                        </div> */ }
                                    </Card.Description>
                                    {
                                        {
                                            'ROLE_ADMIN': this.props.restaurantData.isLoading ? (<Loader active/>) : (
                                                <Card.Content extra>
                                                    <Button size='small'
                                                            as={Link} to={`/restaurant-edit/${list[key]['id']}`}
                                                    >Edit</Button>
                                                    <Button size='small' color='red'
                                                            onClick={this.confirmOpen}
                                                    >Delete restaurant</Button>
                                                    <Confirm
                                                        open={confirmOpen}
                                                        value={list[key]['id']}
                                                        onCancel={this.handleCancel}
                                                        onConfirm={this.handleConfirm}
                                                    />
                                                    <Button color='green' className='checkout-button'
                                                            as={Link} to={`/restaurant/${list[key]['id']}`}
                                                    >Show restaurant</Button>
                                                </Card.Content>
                                            ),
                                            'ROLE_USER': <Button color='green' className='checkout-button'
                                                                 as={Link} to={`/restaurant/${list[key]['id']}`}
                                            >Proceed to checkout</Button>
                                        }[this.props.role]
                                    }
                                </Card.Content>
                            </Card>
                        ))
                    }
                </Card.Group>
                {
                    {
                        'ROLE_ADMIN': <Button size='large'
                                              color='green'
                                              className='newRestaurantButton'
                                              as={Link} to={`/restaurant-edit/`}>
                            New restaurant
                        </Button>
                    }[this.props.role]
                }
            </Container>
        );
    }
}

const mapStateToList = (state) => {
    return {
        userData: state.userData,
        restaurantData: state.restaurantData,
        apiData: state.apiData,
        role: state.userData.role
    };
};

const mapDispatchList = (dispatch) => (
    {
        performRestaurantsRequest: () => (
            dispatch(performRestaurantsRequest())
        ),
        performRestaurantSave: (url, fields, refreshUrl) => (
            dispatch(performRestaurantPut(url, fields, refreshUrl))
        ),
        performRestaurantsPreviewRequest: (url, fields) => (
            dispatch(performRestaurantsPreviewRequest(url, fields))
        )
    }
);

const ListDisplay = connect(
    mapStateToList,
    mapDispatchList
)(List);

export default RestaurantListWrapped;
