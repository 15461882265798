import {combineReducers} from "redux";
import userReducer from '../reducers/Login';
import registrationReducer from '../reducers/Registration';
import forgotPasswordReducer from '../reducers/ForgotPassword';
import restaurantReducer from './Restaurant';
import propertyReducer from './Property';
import ApiDataReducer from './ApiData';
import OrderReducer from './Order';
import TransferReducer from './Transfer';

const reducer = combineReducers({
    userData: userReducer,
    registrationData: registrationReducer,
    forgotPasswordData: forgotPasswordReducer,
    restaurantData: restaurantReducer,
    apiData: ApiDataReducer,
    order: OrderReducer,
    propertyData: propertyReducer,
    transfer: TransferReducer,
});

export default reducer;
