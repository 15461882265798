import {APIDATA_SUCCESS, APIDATA_LOADING, APIDATA_ERROR, APIDATA_CLEAR_ERROR, SET_APIDATA_REFRESH_URL} from '../actions/ApiData';

const initialState = {
    isLoading: false,
    data: {},
    url: '',
    refreshUrl: '',
    errorMessage: '',
    error: false
};

function ApiDataReducer(state = initialState, action) {
    switch (action.type) {
        case 'APIDATA_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, data: Object.assign({}, state.data, action.data)} );
        }
        case 'APIDATA_LOADING': {
            return Object.assign({}, state, {isLoading: true, refreshUrl:''});
        }
        case 'APIDATA_ERROR': {
            return Object.assign({}, state, {errorMessage: action.statusText, error: true});
        }
        case 'APIDATA_CLEAR_ERROR': {
            return Object.assign({}, state, {errorMessage: '', error: false});
        }
        case 'SET_APIDATA_REFRESH_URL': {
            return Object.assign({}, state, {refreshUrl: action.url, data: {}});
        }
        default: {
            return state;
        }
    }
}

export default ApiDataReducer;
