import React from 'react';
import { connect } from 'react-redux';
import { Button, Input, Rating } from 'semantic-ui-react';
import {  performRestaurantReviewPost } from '../../actions/Restaurant';

class RestaurantReview extends React.Component {
    state = {
        isLoading: true,
        fields: {
            overall: 0,
            food: 0,
            service: 0,
            ambience: 0,
            text: ''
        }
    };

    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.currentTarget.getAttribute('name')] = e.target.getAttribute('aria-posinset');
        this.setState({
            fields
        });
    };

    submitReview = (key, value) => {
        const fields = this.state.fields;
        fields.overall = parseInt(fields.overall)
        fields.food = parseInt(fields.food)
        fields.service = parseInt(fields.service)
        fields.ambience = parseInt(fields.ambience)
        fields.restaurant = '/restaurants/' + this.props.restId
        this.props.performRestaurantRequest(fields)
        this.props.closeReview();
    }


    render () {
        const overall = this.state.fields.overall;
        const food = this.state.fields.food;
        const service = this.state.fields.service;
        const ambience = this.state.fields.ambience;
        return (
            <div className='new-review'>
                <div>
                    <div>Value: {overall}</div>
                    <Rating icon='star' rating={overall} maxRating={5} name='overall' onClick={this.handleChange} />
                </div>
                <div>
                    <div>Food: {food}</div>
                    <Rating icon='star' rating={food} maxRating={5} name='food' onClick={this.handleChange} />
                </div>
                <div>
                    <div>Service: {service}</div>
                    <Rating icon='star' rating={service} name='service' maxRating={5} onClick={this.handleChange} />
                </div>
                <div>
                    <div>Ambiance: {ambience}</div>
                    <Rating icon='star' rating={ambience} maxRating={5} name='ambience' onClick={this.handleChange} />
                </div>
                <br/>
                <div>
                    Text: <Input name='text' maxLength="100" onChange={this.handleChange} type='text' />
                </div>
                <Button color='green' onClick={this.submitReview} >Save</Button>
            </div>
        );
    }
}

const mapStateRestaurantReview = (state) => {
    return {
        restaurantData: state.restaurantData,
        role: state.userData.role,
        order: state.order
    };
};

const mapDispatchRestaurantReview = (dispatch) => (
    {
        performRestaurantRequest: (fields) => (
            dispatch(performRestaurantReviewPost(fields))
        ),
    }
);

const RestaurantReviewDisplay = connect(
    mapStateRestaurantReview,
    mapDispatchRestaurantReview
)(RestaurantReview);

export default RestaurantReviewDisplay;
