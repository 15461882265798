import React from 'react';
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {performRegistration, regError} from '../actions/Registration';


class RegisterForm extends React.Component {

    state = {
        fields: {
            email: '',
            username: '',
            password: '',
            userType: '',
            type: 'registration'
        },
        valid: false,
        errorMessage: ''
    };

    onChange = (e) => {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        this.setState({
            fields
        })
    };

    dropDownOnChange = (e,data) => {
        const fields = this.state.fields;
        fields[data.name] = data.value;

        this.setState({
            fields
        })
    }

    performRegistration = () => {
        const fields = this.state.fields;
        const errorMessage = 'You have to fill the fields correct';
        if(!fields.email || !fields.username || !fields.password || !fields.userType) {
            this.setState({valid: false, errorMessage: errorMessage});
            this.props.formSubmitError(errorMessage);
        } else {
            this.setState({valid: true, errorMessage: ''});
            this.props.onFormSubmit(this.state.fields);
        }
    };

    render() {
        const userType = [
            { key: 'restaurant', value: 'restaurant', text: 'Restaurant' },
            { key: 'renter', value: 'renter', text: 'Renter' },
            //{ key: 'transfer', value: 'transfer', text: 'Transfer' },
        ]
        return (
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Register an account account
                    </Header>
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input fluid icon='mail' name="email" iconPosition='left' placeholder='E-mail address'
                                        onChange={this.onChange}/>
                            <Form.Input fluid icon='user' name="username" iconPosition='left' placeholder='User name'
                                        onChange={this.onChange}/>
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                name="password"
                                onChange={this.onChange}
                            />
                            <Form.Dropdown
                                selection
                                placeholder='Your user type'
                                name='userType'
                                options={userType}
                                onChange={this.dropDownOnChange}/>
                            {
                                {
                                    LOADING: <div className='ui active centered inline loader'/>,
                                    READY: (
                                        <Button color='teal' fluid size='large' onClick={this.performRegistration}>
                                            Register
                                        </Button>
                                    ),
                                    SUCCESS: (
                                        <Message>
                                            User registered. Please check your emails.
                                        </Message>
                                    ),
                                    ERROR: (<div>
                                            <Button color='teal' fluid size='large' onClick={this.performRegistration}>
                                                Register
                                            </Button>
                                            <Message negative>
                                                <p>{this.props.state.registrationData.statusText}</p>
                                            </Message>
                                        </div>
                                    ),
                                }[this.props.state.registrationData.status]
                            }
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToRegisterForm = (state) => {
    return {
        state
    }
};

const mapDispatchRegisterForm = (dispatch) => (
    {
        onFormSubmit: (fields) => (
            dispatch(performRegistration(fields))
        ),
        formSubmitError: (statusText) => (
            dispatch(regError(statusText))
        )
    }
);

const RegisterFormDisplay = connect(
    mapStateToRegisterForm,
    mapDispatchRegisterForm
)(RegisterForm);

export default RegisterFormDisplay

