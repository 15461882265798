import API from "../utils/API";
import React from "react";
import { ApiDataError } from './ApiData';

export const RESTAURANT_LOADING = 'RESTAURANT_LOADING';
function loading () {
    return {type: RESTAURANT_LOADING};
}

export const RESTAURANTS_SUCCESS = 'RESTAURANTS_SUCCESS';
function populateRestaurantsData (data) {
    return {type: RESTAURANTS_SUCCESS, data};
}
export const RESTAURANT_SUCCESS = 'RESTAURANT_SUCCESS';
function populateRestaurantData (data) {
    return {type: RESTAURANT_SUCCESS, data};
}
export const RESTAURANT_IMAGE_SUCCESS = 'RESTAURANT_IMAGE_SUCCESS';
function populateRestaurantImage (data) {
    return {type: RESTAURANT_IMAGE_SUCCESS, data};
}
export const RESTAURANT_ERROR = 'RESTAURANT_ERROR';
export function apartmentError (statusText) {
    return {type: RESTAURANT_ERROR, statusText};
}

export function performRestaurantsRequest() {
    return dispatch => {
        dispatch(loading());
        API.get('restaurants')
            .then(res => {
                dispatch(populateRestaurantsData(res.data['hydra:member']));
            })
            .catch((error) => {
                // TODO Hadle error
                if(error.response) {
                    dispatch(apartmentError(error.response.data.message));
                }
            })
        
    }
}

export function performRestaurantsPreviewRequest(fields) {
    return dispatch => {
        dispatch(loading());
        API.post('restaurants_preview', fields)
            .then(res => {
                dispatch(populateRestaurantsData(res.data['hydra:member']));
            })
            .catch((error) => {
                // TODO Hadle error
                if(error.response) {
                    dispatch(apartmentError(error.response.data.message));
                }
            })

    }
}

export function performRestaurantRequest(id) {
    return dispatch => {
        dispatch(loading());
        API.get('restaurants/'+id)
            .then(res => {
                dispatch(populateRestaurantData(res.data));
            })
            .catch((error) => {
                // TODO Hadle error
            })

    }
}
export function performRestaurantImagesRequest(id) {
    return dispatch => {
        dispatch(loading());
        API.get('restaurants/'+id+'/images')
            .then(res => {
                dispatch(populateRestaurantImage(res.data['hydra:member']));
            })
            .catch((error) => {
                // TODO Hadle error
            })

    }
}

export function performRestaurantPut(url, fields, refreshUrl) {
    return dispatch => {
        dispatch(loading());
        API.put(url, fields)
            .then(res => {
                dispatch(performRestaurantsRequest());
            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data.message));
            })
    }
}

export function performRestaurantReviewPost(fields) {
    return dispatch => {
        dispatch(loading());
        API.post('reviews', fields)
            .then(res => {

            })
            .catch((error) => {
                // TODO Hadle error
                dispatch(ApiDataError(error.response.data.message));
            })
    }
}
