import React from 'react';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import { Sidebar } from 'semantic-ui-react';

import PrivateRoute from "./components/PrivateRoute";
import ProtectedRoute from "./components/ProtectedRoute";

import LoginFormDisplay from "./components/LoginForm";
import LogoutDisplay from "./components/LogoutDisplay";
import RegisterFormDisplay from "./components/RegisterForm";
import ForgotPasswordDisplay from "./components/ForgotPasswordForm";
import RegFormDisplay from "./components/RegForm";
import RestaurantList from "./components/Restaurant/List";
import Restaurant from "./components/Order/Restaurant";
import OrderList from "./components/Order/OrderList";
import TransferList from "./components/Transfer/TransferList";
import RestaurantEditWrapper from "./components/Restaurant/RestaurantEditWrapper";
import PropertyList from "./components/Properties/List";
import PropertyEdit from "./components/Properties/EditProperty";
import RequestTransfer from "./components/Transfer/RequestTransfer";

import CookieConsent from "react-cookie-consent";

import HeaderMenuWrapper from "./components/HeaderMenu";
import HeaderMenuList from "./components/HeaderMenuList";
import store from "./utils/Store";
import API from "./utils/API";
import './css/style.css';
import {getRandomNumber} from './utils/Utils';

// Local storage
const LOCAL_STORAGE_KEY_TOKEN = 'mapnbag-api-token';
const LOCAL_STORAGE_KEY_REFRESH_TOKEN = 'mapnbag-api-refresh-token';
let currentToken;
const useLocalStorage = (typeof localStorage !== 'undefined');
store.subscribe(() => {
    let previousToken = currentToken;
    currentToken = store.getState().userData.token;
    if (previousToken !== currentToken) {
        console.log("New token. Saving token to Localstorage");
        if (useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, currentToken);
            localStorage.setItem(LOCAL_STORAGE_KEY_REFRESH_TOKEN, store.getState().userData.refreshToken);
        }
        if(currentToken !== null) {
            API.defaults.headers['Authorization'] = 'Bearer ' + currentToken;
        }
    }

    if (currentToken === '') {
        if (useLocalStorage) {
            localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE_KEY_REFRESH_TOKEN);
        }
    }
});

class App extends React.Component {
    state = {}
    handleSidebarHide = () => this.setState({ sidebarOpened: false });
    handleToggle = () => this.setState({ sidebarOpened: true });

    render() {
        const { sidebarOpened } = this.state;
        let defaultPath = "/restaurants";
        if(store.getState().userData.role === 'ROLE_RENTER') {
            defaultPath = "/properties";
        }
        else if(store.getState().userData.role === 'ROLE_RESTAURANT') {
            defaultPath = "/order_list";
        }
        else if(store.getState().userData.role === 'ROLE_TRANSFER') {
            defaultPath = "/transfer-list";
        }
        return (
            <BrowserRouter>
                <Provider store={store}>
                    <Sidebar.Pushable className={'main'}
                                      //style={store.getState().userData.token? {} : {backgroundImage: 'url("/images/hungary/' + getRandomNumber(5) + '.jpg")'}}
                                     >
                        <HeaderMenuList
                            handleSidebarHide={() => this.setState({ sidebarOpened: false })}
                            sidebarOpened={sidebarOpened}
                        />
                        <Sidebar.Pusher dimmed={sidebarOpened}>
                            <HeaderMenuWrapper
                                handleToggle={() => this.setState({ sidebarOpened: true })}
                            />
                            <Switch>
                                <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_RESTAURANT']} path='/restaurant-edit' component={RestaurantEditWrapper}/>
                                <PrivateRoute path='/restaurants' component={RestaurantList}/>
                                <Route path='/restaurant' component={Restaurant}/>
                                <PrivateRoute path='/order_list' component={OrderList}/>
                                <ProtectedRoute allowedRoles={['ROLE_RENTER']} path='/properties' component={PropertyList}/>
                                <ProtectedRoute allowedRoles={['ROLE_RENTER']} path='/property-edit' component={PropertyEdit}/>
                                <ProtectedRoute allowedRoles={['ROLE_USER']} path='/request-transfer' component={RequestTransfer}/>
                                <PrivateRoute path='/transfer-list' component={TransferList}/>
                                <Route path='/login' component={LoginFormDisplay}/>
                                <Route path='/logout' component={LogoutDisplay}/>
                                <Route path='/registration' component={RegisterFormDisplay}/>
                                <Route path='/forgot-password' component={ForgotPasswordDisplay}/>
                                <Route path='/reg' component={RegFormDisplay}/>
                                <Route path='/restaurants-preview' component={RestaurantList}/>
                                <Route exact path='/' render={() => (
                                    <Redirect
                                        to={defaultPath}
                                    />
                                )}/>
                            </Switch>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                    <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
                </Provider>
            </BrowserRouter>
        )
    }
}


export default App;
