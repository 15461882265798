import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Confirm, Container, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { performPropertyPut, performPropertiesRequest } from '../../actions/Property';


const PropertyListWrapped = () => (
    <Container className={'list-width'}>
        <Grid>
            <ListDisplay/>
        </Grid>
    </Container>
);

class List extends React.Component {
    state = {
        confirmOpen: false,
        propertyToDelete: 0
    };

    componentDidMount () {
        this.props.performPropertiesRequest();
    };

    handleConfirm = (key, value) => {
        this.setState({ confirmOpen: false });
        this.props.performPropertySave('properties/' + this.state.propertyToDelete, { status: 0 }, 'properties/');
    };

    handleCancel = () => this.setState({ confirmOpen: false });

    confirmOpen = (e) => {
        const propertyToDelete = e.target.value;
        this.setState({ propertyToDelete:propertyToDelete,  confirmOpen: true })
    }

    render () {
        const list = this.props.propertyData.list;
        const confirmOpen = this.state.confirmOpen;

        return (
            <Grid.Column width={16} textAlign='center'>
                <Card.Group itemsPerRow='5'>
                    {
                        Object.keys(list).map((key) => (
                            <Card key={key} className={list[key]['status'] === 0 ? 'disabled-property': ''}>
                                <Card.Content>
                                    <Card.Header content={list[key]['name']}/>
                                    <Card.Description content={list[key]['street']}/>
                                    <Card.Description>
                                        Url: {window.location.host}/reg/{list[key]['code']}
                                    </Card.Description>
                                    {
                                        list[key]['status'] === 1 ? (
                                            <Card.Content extra>
                                                <Button size='small'
                                                        as={Link} to={`/property-edit/${list[key]['id']}`}
                                                >Edit</Button>
                                                <Button size='small' color='red'
                                                        onClick={this.confirmOpen}
                                                        value={list[key]['id']}
                                                >Delete property</Button>
                                                <Confirm
                                                    open={confirmOpen}
                                                    onCancel={this.handleCancel}
                                                    onConfirm={this.handleConfirm}
                                                />
                                            </Card.Content>
                                        ) : ('')
                                    }

                                </Card.Content>
                            </Card>
                        ))
                    }
                </Card.Group>
                <Button size='large'
                        color='green'
                        className='newRestaurantButton'
                        as={Link} to={`/property-edit/`}>
                    New property
                </Button>
            </Grid.Column>
        );
    }
}

const mapStateToList = (state) => {
    return {
        propertyData: state.propertyData,
        apiData: state.apiData,
        role: state.userData.role
    };
};

const mapDispatchList = (dispatch) => (
    {
        performPropertiesRequest: () => (
            dispatch(performPropertiesRequest())
        ),
        performPropertySave: (url, fields, refreshUrl) => (
            dispatch(performPropertyPut(url, fields, refreshUrl))
        )
    }
);

const ListDisplay = connect(
    mapStateToList,
    mapDispatchList
)(List);

export default PropertyListWrapped;
