import React from 'react';
import { Button, Form, Grid, Header, Message, Segment, Loader } from 'semantic-ui-react';
import {connect} from 'react-redux';
import {performRegistration, regError} from '../actions/Registration';
import { performApiRawRequest } from '../actions/ApiData';
import { getUrlFromPath } from '../utils/Utils';

class RegisterForm extends React.Component {

    state = {
        isLoading: true,
        fields: {
            email: '',
            username: '',
            password: ''
        },
        valid: false,
        errorMessage: ''
    };

    onChange = (e) => {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        this.setState({
            fields
        })
    };

    componentDidMount () {
        this.props.performPropertyRequest('reg?code=' + getUrlFromPath(this.props.location.pathname, 2));
    }

    componentDidUpdate (prevProps) {
        if(this.props.state.apiData.data.propertyId && prevProps.state.apiData.data.propertyId !== this.props.state.apiData.data.propertyId) {
            this.setState({isLoading:false})
        }
    }

    performRegistration = () => {
        const fields = this.state.fields;
        const errorMessage = 'You have to fill the fields correct';
        if(!fields.email || !fields.username || !fields.password) {
            this.setState({valid: false, errorMessage: errorMessage});
            this.props.formSubmitError(errorMessage);
        } else {
            this.setState({valid: true, errorMessage: ''});
            let fields = this.state.fields;
            fields.userType = 'user';
            fields.propertyId = this.props.state.apiData.data.propertyId;
            this.props.onFormSubmit(this.state.fields);
        }
    };

    render() {
        return (
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Register an user account
                    </Header>
                    {this.state.isLoading ? (<Loader active/>) : (
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input fluid icon='mail' name="email" iconPosition='left' placeholder='E-mail address'
                                        onChange={this.onChange}/>
                            <Form.Input fluid icon='user' name="username" iconPosition='left' placeholder='User name'
                                        onChange={this.onChange}/>
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                name="password"
                                onChange={this.onChange}
                            />
                            {
                                {
                                    LOADING: <div className='ui active centered inline loader'/>,
                                    READY: (
                                        <Button color='teal' fluid size='large' onClick={this.performRegistration}>
                                            Register
                                        </Button>
                                    ),
                                    SUCCESS: (
                                        <Message>
                                            User registered. Please check yout emails.
                                        </Message>
                                    ),
                                    ERROR: (<div>
                                            <Button color='teal' fluid size='large' onClick={this.performRegistration}>
                                                Register
                                            </Button>
                                            <Message negative>
                                                <p>{this.props.state.registrationData.statusText}</p>
                                            </Message>
                                        </div>
                                    ),
                                }[this.props.state.registrationData.status]
                            }
                        </Segment>
                    </Form>) }
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToRegisterForm = (state) => {
    return {
        state
    }
};

const mapDispatchRegisterForm = (dispatch) => (
    {
        performPropertyRequest: (url) => (
            dispatch(performApiRawRequest(url))
        ),
        onFormSubmit: (fields) => (
            dispatch(performRegistration(fields))
        ),
        formSubmitError: (statusText) => (
            dispatch(regError(statusText))
        )
    }
);

const RegisterFormDisplay = connect(
    mapStateToRegisterForm,
    mapDispatchRegisterForm
)(RegisterForm);

export default RegisterFormDisplay

