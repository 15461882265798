import {Button, Container, Icon, Menu, Segment, Sticky} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import React from "react";

class HeaderMenu extends React.Component {

    render() {
        return (
            <Sticky className="header-banner">
                <Segment

                    textAlign='center'
                    style={{ padding: '1em 0em' }}
                    vertical
                >
                    <Container className={'fullwidth'}>
                        <Menu inverted pointing secondary size='large'>
                            <Menu.Item className='logoLink' position='left' as={Link} to='/'/>
                            {
                                this.props.userData.token ? (
                                    <Menu.Item position='right' onClick={this.props.handleToggle}>
                                        <Icon name='sidebar' />
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item position='right'>
                                        <Button as={Link}
                                                to='/login' inverted>
                                            Log in
                                        </Button>
                                    </Menu.Item>
                                )
                            }
                        </Menu>
                    </Container>
                </Segment>
            </Sticky>
        )
    }
}

const mapStateToHeaderMenu = (state) => {
    return {
        userData: state.userData
    };
};

const HeaderMenuWrapper = connect(
    mapStateToHeaderMenu
)(HeaderMenu);


export default HeaderMenuWrapper;
