import React from 'react';
import { Button, Confirm, Grid, Header, Table } from 'semantic-ui-react';
import { performTransferStatusPost } from '../../actions/Transfer';
import { connect } from 'react-redux';


class TransferListTransfer extends React.Component {
    state = {
        confirmOpen: false,
        propertyToDelete: 0
    };

    setStatus = (status) => {
        if(status === 0) {
            return 'warning';
        } else if(status === 1) {
            return 'positive';
        }
        else if(status === -1) {
            return 'negative';
        }
    }

    handleConfirm = (key, value) => {
        this.setState({ confirmOpen: false });
        const fields = {
            'id' : this.state.propertyToDelete,
            'status': -1
        }
        this.props.performTransferListPost(fields)
    };

    handleCancel = () => this.setState({ confirmOpen: false });

    confirmOpen = (e) => {
        const propertyToDelete = e.target.value;
        this.setState({ propertyToDelete:propertyToDelete,  confirmOpen: true })
    }

    render () {
        const list = this.props.list?? [];
        const confirmOpen = this.state.confirmOpen;
        return (
            <Grid>
                <Header as='h1'
                        content={'Order list'}
                        textAlign='center'/>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>User details</Table.HeaderCell>
                            <Table.HeaderCell>Other requests</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Address</Table.HeaderCell>
                            <Table.HeaderCell>Arriving-Departure time</Table.HeaderCell>
                            <Table.HeaderCell>Flight number</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Shuttle price received</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            Object.keys(list).map((key) => (
                                <Table.Row>
                                    <Table.Cell>
                                        {list[key]['user']['username']} <br/>
                                        Number of passaners: {list[key]['numberOfPassangers']} <br/>
                                        Phone: {list[key]['user']['phone']}
                                    </Table.Cell>
                                    <Table.Cell>
                                        Booster: {list[key]['booster']} <br/>
                                        Bulky Luggage: {list[key]['bulkyLuggage']} <br/>
                                        Child Seat: {list[key]['childSeat']} <br/>
                                        Infant Carrier: {list[key]['infantCarrier']} <br/>
                                        Number of Luggages: {list[key]['numberOfLuggages']} <br/>
                                        Wheelchar: {list[key]['wheelchair'] ? 'yes' : 'no'} <br/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric"
                                        }).format(new Date(list[key]['transferDate']['date']))}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {list[key]?.property?.zip} {list[key]?.property?.street}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {list[key]['transferType'] === 'from-airport' ? (
                                        <>
                                            Airport-City <br/>
                                            Landing time:  {new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric"
                                        }).format(new Date(list[key]['transferDate']['date']))}

                                        </>
                                        ) : (
                                        <>
                                            City-Airport
                                            Departure time from the city: {new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric"
                                        }).format(new Date(list[key]['transferDate']['date']))}
                                        </>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {list[key]['flightNumber']}
                                    </Table.Cell>
                                    <Table.Cell className={this.setStatus(list[key]['status'])}>
                                        {list[key][['statusText']]}
                                        {
                                            list[key]['status'] === 0 || list[key]['status'] === 1 ? (
                                                <>
                                                    <Button value={list[key]['id']}  color='red' className='decline-button'
                                                            onClick={this.confirmOpen}>Decline</Button>
                                                    <Confirm
                                                        open={confirmOpen}
                                                        onCancel={this.handleCancel}
                                                        onConfirm={this.handleConfirm}
                                                    />
                                                </>
                                            ) : ('')
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {list[key]['price']} €
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </Grid>
        );
    }
}

const mapStateTransferList= (state) => {
    return {
        order: state.transfer
    };
};

const mapDispatchTransferList = (dispatch) => (
    {
        performTransferListPost: (fields) => (
            dispatch(performTransferStatusPost(fields))
        )
    }
);

const TransferListTransferDisplay = connect(
    mapStateTransferList,
    mapDispatchTransferList
)(TransferListTransfer);

export default TransferListTransferDisplay;
