import React from 'react';
import {connect} from "react-redux";
import { performApiDataPut, performApiDataPost } from "../../actions/ApiData";
import { Button, Input } from 'semantic-ui-react';

class MenuEditCard extends React.Component {
    state = {
        menuItem: this.props.item || [],
        fields: {
            name: this.props.item ? this.props.item.name : '',
            price: this.props.item ? this.props.item.price : '',
            description: this.props.item ? this.props.item.description : ''
        },
        valid: true,
        category: this.props.category || ''
    }

    onChange = (e, data) => {
        let fields = this.state.fields;
        let value = data.value;
        if (data.type === 'number') {
            value = parseInt(value);
        }
        if(data.value === '') {
            this.setState({valid: false})
        }
        else {
            fields[data.name] = value;
            this.setState({
                fields
            });
            this.setState({valid: true})
        }
    }

    saveMenuItem = (key, value) => {
        const fields = this.state.fields;
        if(this.state.valid){
            this.props.performMenuItemSave(value.value, fields, this.props.apiData.data.Restaurants['@id']);
        }
        this.props.closeEditMenu(key, value);
    };

    postMenuItem = (key, value) => {
        let fields = this.state.fields;
        let url = ''
        fields.status = 1;
        if(this.props.type === 'category') {
            url = '/dish_categories';
            fields.dishCategoryRestaurant = this.props.apiData.data.Restaurants['@id'];
        } else {
            fields.category = this.props.category;
            url = '/dishes';
            fields.dishRestaurant = this.props.apiData.data.Restaurants['@id'];
        }
        if(this.state.valid){
            this.props.performMenuItemPost(url, fields, this.props.apiData.data.Restaurants['@id']);
        }
        this.props.closeEditMenu(key, value);
    };


    render () {
        return (
            <div className={this.props.class}>
                Name: <Input name='name'
                       value={this.state.fields.name}
                       onChange={this.onChange}
                       type='text'
            />
                {this.props.type === 'dish' ? (<span> Price: <Input name='price'
                                     value={this.state.fields.price}
                                     onChange={this.onChange}
                                     type='number'
                                    />
                                Description: <Input name='description'
                                                    value={this.state.fields.description}
                                                    onChange={this.onChange}
                                                    type='text'
                    /></span>) : ''}

                {
                    this.props.action === 'put' ? (
                            <Button color='green' value={this.state.menuItem['@id']} onClick={this.saveMenuItem}>Save</Button>
                        )
                        : (
                            <Button color='green' onClick={this.postMenuItem}>Save new</Button>
                    )
                }
            </div>
        )
    }
}

const mapStateToMenuEditCard = (state) => {
    return {
        apiData: state.apiData,
    }
};

const mapDispatchMenuEditCard = (dispatch) => (
    {
        performMenuItemSave: (url, fields, refreshUrl) => (
            dispatch(performApiDataPut(url, fields, refreshUrl))
        ),
        performMenuItemPost: (url, fields, refreshUrl) => (
            dispatch(performApiDataPost(url, fields, refreshUrl))
        ),
    }
);

const MenuEditCardDisplay = connect(
    mapStateToMenuEditCard,
    mapDispatchMenuEditCard
)(MenuEditCard);

export default MenuEditCardDisplay;
