import {ORDER_LOADING, MY_ORDER_POST_SUCCESS, ORDER_ERROR, ORDER_REFRESH_URL_CLEAR, POPULATE_ORDER_LIST} from '../actions/Order';

const initialState = {
    isLoading: false,
    orders: {
    },
    order_list: {},
    refreshUrl: ''
};

function OrderReducer(state = initialState, action) {
    switch (action.type) {
        case 'MY_ORDER_POST_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, refreshUrl: '/restaurants'} );
        }
        case 'MY_ORDER_POST_RENTER_SUCCESS': {
            return Object.assign({}, state, {isLoading: false, refreshUrl: '/order_list'} );
        }
        case 'ORDER_LOADING': {
            return Object.assign({}, state, {isLoading: true});
        }
        case 'ORDER_ERROR': {
            return Object.assign({}, state, {});
        }
        case 'ORDER_REFRESH_URL_CLEAR': {
            return Object.assign({}, state, {});
        }
        case 'POPULATE_ORDER_LIST': {
            console.log(action)
            return Object.assign({}, state, {isLoading: false, order_list: action.data, refreshUrl: '' });
        }
        default: {
            return state;
        }
    }
}

export default OrderReducer;
