import React from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import { performApiDataPut } from '../../actions/ApiData';
import { connect } from 'react-redux';

class MenuShowCard extends React.Component {
    state = {
        menuItem: this.props.item,
        confirmOpen: false
    }

    openEditMenu = (key, value) => {
        this.props.openEditMenu(key, value);
    };

    handleConfirm = (key, value) => {
        this.setState({ confirmOpen: false })
        this.props.performMenuItemSave(value.value, {status: 0}, this.props.apiData.data.Restaurants['@id']);
    }
    handleCancel = () => this.setState({ confirmOpen: false })

    confirmOpen = () => this.setState({ confirmOpen: true })

    render () {
        const confirmOpen = this.state.confirmOpen;
        return (
            <div className={this.props.class}>
                <span>Name: {this.props.item['name']}</span>
                <span>Price (Ft): {this.props.item['price']}</span>
                <Button color='blue' onClick={this.openEditMenu} value={this.props.item['@id']}>Edit</Button>
                <Button color='red'  onClick={this.confirmOpen} value={this.props.item['@id']}>Delete</Button>
                <Confirm
                    open={confirmOpen}
                    value={this.props.item['@id']}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleConfirm}
                />
            </div>
        )
    }
}

const mapStateToMenuShowCard = (state) => {
    return {
        apiData: state.apiData,
    }
};

const mapDispatchMenuShowCard = (dispatch) => (
    {
        performMenuItemSave: (url, fields, refreshUrl) => (
            dispatch(performApiDataPut(url, fields, refreshUrl))
        )
    }
);

const MenuShowCardDisplay = connect(
    mapStateToMenuShowCard,
    mapDispatchMenuShowCard
)(MenuShowCard);

export default MenuShowCardDisplay;
