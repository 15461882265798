import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Container,
    Header,
    Icon,
    Input,
    Rating,
    List,
    Ref,
    Segment,
    Image,
    Tab, Checkbox
} from 'semantic-ui-react';
import Review from './Review';
import { filterDishes } from '../../utils/Utils';
import { performRestaurantRequest, performRestaurantImagesRequest } from '../../actions/Restaurant';
import { performOrderPut, OrderRefreshUrlClear } from '../../actions/Order';
import ImageGallery from 'react-image-gallery';
import { getUrlFromPath } from '../../utils/Utils';
import Constants from '../../utils/Constants';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import ErrorMessage from '../General/ErrorMessage';

class Restaurant extends React.Component {
    state = {
        isLoading: true,
        images: [],
        fields: {
            guests: '',
            orderDate: '',
            restaurant: getUrlFromPath(this.props.location.pathname, 2)
        },
        message: false,
        reviewActive: false,
        summary: false,
        termsChecked: false
    };
    contextRef = createRef();

    handleChange = (event, { name, value }) => {
        let fields = this.state.fields;
        fields[name] = value;
        this.setState({ fields });
    };

    componentDidMount () {

        this.props.performRestaurantRequest(getUrlFromPath(this.props.location.pathname, 2));
        this.props.performRestaurantImagesRequest(getUrlFromPath(this.props.location.pathname, 2));
        if (Object.keys(this.props.restaurantData.images).length !== 0) {
            const images = [];
            Object.keys(this.props.restaurantData.images).map((key) => {
                images[key] = { original: Constants.imageUrl + this.props.restaurantData.images[key].contentUrl };
                images[1] = { original: Constants.imageUrl + this.props.restaurantData.images[key].contentUrl };
                images[2] = { original: Constants.imageUrl + this.props.restaurantData.images[key].contentUrl };
                images[3] = { original: Constants.imageUrl + this.props.restaurantData.images[key].contentUrl };
            });
            this.setState({ images });
        }

      /*  if(getUrlFromPath(this.props.location.pathname, 3) === '1') {
            this.setState({ message: true });
        }*/

    }

    handleTermsChange = (event, data) => {
        this.setState({ termsChecked: data.checked });
    }

    componentDidUpdate (prevProps) {
        if (Object.keys(prevProps.restaurantData.data).length === 0 && Object.keys(this.props.restaurantData.data).length !== 0) {
            this.props.performRestaurantImagesRequest(getUrlFromPath(this.props.location.pathname, 2));
        }

        if (this.props.restaurantData.images !== prevProps.restaurantData.images) {
            const images = [];
            Object.keys(this.props.restaurantData.images).map((key) => {
                images[key] = { original: Constants.imageUrl + this.props.restaurantData.images[key].contentUrl };
            });
            this.setState({ images });
        }

        if (Object.keys(prevProps.restaurantData.images).length === 0 && Object.keys(this.props.restaurantData.images).length !== 0) {
            const images = [];
            Object.keys(this.props.restaurantData.images).map((key) => {
                images[key] = { original: Constants.imageUrl + this.props.restaurantData.images[key].contentUrl };
            });
            this.setState({ images });
        }



        if (prevProps.order.refreshUrl !== '/restaurants' && this.props.order.refreshUrl === '/restaurants') {
            const claim = atob(this.props.token.split('.')[1]);
            const email = JSON.parse(claim)['email'];
            window.open(`http://${window.location.host}/otp/pay_rest.php?rest=${this.props.restaurantData.data.id}&email=${email}`, '_self');
        }
    }

    performOrderSummary = (key, value) => {

        const fields = this.state.fields;
        let valid = true;
        Object.keys(fields).map((key) => {
            if (!fields[key]) {
                valid = false;
            }
        });
        if (valid) {
            this.setState({ summary: true });
        } else {
            console.log('not valid');
        }
    };

    performOrderPut = () => {
        const fields = this.state.fields;
        this.props.performOrderPut(fields);
    };

    confirmMessage = () => {
        this.setState({ message: false });
        this.props.OrderRefreshUrlClear();
        this.props.history.push(this.props.order.refreshUrl);
    };

    openReview = () => {
        this.setState({ reviewActive: true });
    };

    closeReview = () => {
        this.setState({ reviewActive: false });
    };
    otpPayOnclick = () => {
        // eslint-disable-next-line no-restricted-globals
        if(this.state.termsChecked) {
            this.performOrderPut();
        }
        /*setTimeout(() => {

        }, 1000);*/
        //location.href = `www.test.com/?a=${queryA}&b=${queryB}`
    }
    render () {
        const { images, message } = this.state;
        let dishCategories = filterDishes(this.props.restaurantData.dishCategories)
        const reviews = this.props.restaurantData.reviews ? this.props.restaurantData.reviews : [];

        const panes = [
            /*{
                menuItem: 'Pictures',
                render: () => <Tab.Pane>
                    <div>
                        {
                            images ? <ImageGallery items={images}
                                                   showThumbnails={false}
                                                   showFullscreenButton={false}
                                                   showPlayButton={false}/> : ''
                        }
                    </div>
                </Tab.Pane>
            },*/
            {
                menuItem: 'Details',
                render: () => <Tab.Pane>
                    <div>
                        <Header className="rest-header"
                                as="h1">{this.props.restaurantData.data.name}</Header>
                        <p><strong>Address:</strong> {this.props.restaurantData.data.street}</p>
                        <a href={'https://maps.google.com/?q=Budapest ' + this.props.restaurantData.data.street }>See it on map</a>
                    </div>
                </Tab.Pane>
            },
            {
                menuItem: 'Menu', render: () => <Tab.Pane>
                    <div className="rest-menu">
                        <Header as="h2">Menu</Header>
                        {
                            Object.keys(dishCategories).map((key) => (
                                <div key={key}>
                                    <Header as="h3">{dishCategories[key]['name']}</Header>
                                    <List className="menu-list">
                                        {
                                            Object.keys(dishCategories[key]['dishes']).map((keyy) => (
                                                <List.Item key={keyy}>
                                                    <Icon name="right triangle"/>
                                                    <List.Content>
                                                        <List.Header>
                                                            <span className="listitem-name"> {dishCategories[key]['dishes'][keyy]['name']} </span>
                                                            <span className="listitem-price"> {dishCategories[key]['dishes'][keyy]['price']} HUF</span>
                                                        </List.Header>
                                                        <List.Description>
                                                            {dishCategories[key]['dishes'][keyy]['description']}
                                                        </List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            ))
                                        }
                                    </List>
                                </div>
                            ))
                        }
                    </div>
                </Tab.Pane>
            },
            {
                menuItem: 'Reviews',
                render: () => <Tab.Pane>
                    <div className="reviews">
                        <Header as="h2">Reviews</Header>
                        {/*
                            this.state.reviewActive ? (<Review restId={getUrlFromPath(this.props.location.pathname, 2)}
                                                               closeReview={this.closeReview}/>) :
                                <Button color="blue" className="write-review" onClick={this.openReview}>Write a
                                    review</Button>
                        */}
                        {
                            Object.keys(reviews).map((key) => (
                                <div key={key} className="review-container">
                                    <Header as="h3">{reviews[key]['user']['username']}</Header>
                                    <div className="review">
                                        <div className="item">
                                            Value: <Rating defaultRating={reviews[key]['overall']}
                                                           maxRating={5}
                                                           disabled/>
                                        </div>
                                        <div className="item">
                                            Food: <Rating defaultRating={reviews[key]['food']} maxRating={5} disabled/>
                                        </div>
                                        <div className="item">
                                            Service: <Rating defaultRating={reviews[key]['service']}
                                                             maxRating={5}
                                                             disabled/>
                                        </div>
                                        <div className="item">
                                            Ambience: <Rating defaultRating={reviews[key]['ambience']}
                                                              maxRating={5}
                                                              disabled/>
                                        </div>
                                        <div className="text">
                                            {reviews[key]['text']}
                                        </div>
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                </Tab.Pane>
            }
        ];

        return (
            <>
                <ErrorMessage error={message}
                              errorMessage="Order has been processed"
                              closeErrorMessage={this.confirmMessage}/>
                {
                    this.state.summary ? (
                        <div className="summary-step">
                            <div>
                                <h2>
                                    The details of my booking
                                </h2>
                            </div>
                            <Image className="summary-image" src={images[0]['original']}
                                   centered={true}
                                   size="medium"
                            />
                            <div className="summary-list">
                                Restaurant name: <b>{this.props.restaurantData.data.name}</b>
                            </div>
                            <div className="summary-list">
                                Address: <b>{this.props.restaurantData.data.street}</b>
                            </div>
                            <div className="summary-list">
                                Number of guests: <b>{this.state.fields.guests}</b>
                            </div>
                            <div className="summary-list">
                                Date: <b>{this.state.fields.orderDate}</b>
                            </div>
                            <div>
                                <div>
                                    I acknowledge that the following personal data stored by the controller, Márton Szende
                                    (Hungary, Budapest, 1066 Dessewffy utca 25-27) in the user database of
                                    https://www.mapnbag.com will be handed over to OTP Mobil Ltd. who is trusted as data
                                    processor. The data transferred by the data controller are the following: name,
                                    email, billing address.
                                    <br/>
                                    The nature and purpose of the data processing operations performed by the processor
                                    are available in the SimplePay Privacy Policy at the following
                                    link: <a target="_blank"
                                             href="https://simplepay.hu/adatkezelesi-tajekoztatok/">https://simplepay.hu/adatkezelesi-tajekoztatok/</a>
                                </div>
                                <Checkbox checked={this.state.termsChecked}
                                          onChange={this.handleTermsChange}
                                          label="Agree"/>
                            </div>
                            <div>
                                <a href="http://simplepartner.hu/PaymentService/Payment_information.pdf"
                                   target="_blank">
                                    <img width="150"
                                         height="150"
                                         src="/images/simple_logo.png"
                                         title="SimplePay - Online payment system"
                                         alt=" SimplePay payment information"/>
                                </a>
                            </div>

                            <div className="summary-checkout">
                                <Button className="proceed-to-checkout"
                                        color="green"
                                        onClick={this.otpPayOnclick}>Proceed
                                    to payment</Button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div>
                                {
                                    images ? <ImageGallery items={images}
                                                           showThumbnails={false}
                                                           showFullscreenButton={false}
                                                           showPlayButton={false}/> : ''
                                }
                            </div>
                            <Container>
                                <div className="restaurant-container">
                                    <div className="restaurant-infos">
                                        <div>
                                            <Ref innerRef={this.contextRef}>
                                                <Segment>
                                                    <Tab panes={panes}/>
                                                </Segment>
                                            </Ref>
                                        </div>
                                    </div>
                                    <div className="reservation-info">
                                        <div className="reservation-container">
                                            Reservation time:
                                            <DateTimeInput
                                                className="order-date"
                                                name="orderDate"
                                                minDate={new Date()}
                                                closable={true}
                                                placeholder="Reservation time"
                                                value={this.state.fields.orderDate}
                                                iconPosition="left"
                                                onChange={this.handleChange}
                                            />
                                            Number of guests: <br/>
                                            <Input name="guests"
                                                   placeholder="Number of guests"
                                                   value={this.state.fields.guests}
                                                   onChange={this.handleChange}
                                                   type="text"
                                                   className="number-of-guests"
                                            />
                                            <Button className="proceed-to-checkout"
                                                    color="green"
                                                    onClick={this.performOrderSummary}>Proceed
                                                to checkout</Button>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </>
                    )
                }
            </>
        );
    }
}

const mapStateRestaurant = (state) => {
    return {
        restaurantData: state.restaurantData,
        role: state.userData.role,
        token: state.userData.token,
        order: state.order
    };
};

const mapDispatchRestaurant = (dispatch) => (
    {
        performRestaurantRequest: (id) => (
            dispatch(performRestaurantRequest(id))
        ),
        performRestaurantImagesRequest: (id) => (
            dispatch(performRestaurantImagesRequest(id))
        ),
        performOrderPut: (fields) => (
            dispatch(performOrderPut(fields))
        ),
        OrderRefreshUrlClear: () => (
            dispatch(OrderRefreshUrlClear())
        )
    }
);

const RestaurantDisplay = connect(
    mapStateRestaurant,
    mapDispatchRestaurant
)(Restaurant);

export default RestaurantDisplay;
