import { Menu, Sidebar } from 'semantic-ui-react';
import {Link} from "react-router-dom";
import {connect } from "react-redux";
import React from "react";

class HeaderMenuList extends React.Component {
    render() {
        return (
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                direction='right'
                onHide={this.props.handleSidebarHide}
                vertical
                visible={this.props.sidebarOpened}
            >
                <Menu.Item as='a' active>
                    Home
                </Menu.Item>
                {
                   /* {
                        'ROLE_ADMIN':    <span>dfdf</span>,
                        'ROLE_USER': <span>fgfgfgfg6t545</span>
                    }[this.props.userData.role]*/
                }
                {
                    this.props.userData.role === 'ROLE_RENTER' ? (
                        <Menu.Item as={Link}
                                   onClick={this.props.handleSidebarHide}
                                   to='/properties'
                        >My properties</Menu.Item>
                    ) : ''
                }
                {
                    this.props.userData.role !== 'ROLE_TRANSFER' ? (
                        <Menu.Item as={Link}
                                   onClick={this.props.handleSidebarHide}
                                   to='/order_list'
                        >Order list</Menu.Item>
                    ) : ''
                }
                {
                    this.props.userData.role === 'ROLE_USER' || this.props.userData.role === 'ROLE_ADMIN' ? (
                        <Menu.Item as={Link}
                                   onClick={this.props.handleSidebarHide}
                                   to='/restaurants'
                        >Restaurants</Menu.Item>
                    ) : ''
                }
                {
                    this.props.userData.role === 'ROLE_USER' || this.props.userData.role === 'ROLE_ADMIN' ? (
                        <Menu.Item  as={Link}
                                    to='/transfers'
                                    onClick={this.props.handleSidebarHide}>Transfers</Menu.Item>
                    ) : ''
                }
                {
                    this.props.userData.role === 'ROLE_USER' ? (
                        <Menu.Item  as={Link}
                                    to='/request-transfer'
                                    onClick={this.props.handleSidebarHide}>Request transfer</Menu.Item>
                    ) : ''
                }
                {
                    this.props.userData.role !== 'ROLE_RENTER' && this.props.userData.role !== 'ROLE_RESTAURANT' ? (
                        <Menu.Item  as={Link}
                                    to='/transfer-list'
                                    onClick={this.props.handleSidebarHide}>Transfer list</Menu.Item>
                    ) : ''
                }
                <Menu.Item  as={Link}
                            to='/logout'
                            onClick={this.props.handleSidebarHide}>Logout</Menu.Item>
            </Sidebar>
        )
    }
}

const mapStateToHeaderMenuList = (state) => {
    return {
        userData: state.userData
    };
};

const HeaderMenuListWrapper = connect(
    mapStateToHeaderMenuList
)(HeaderMenuList);


export default HeaderMenuListWrapper;
