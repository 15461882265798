import API from "../utils/API";

export const LOGIN_LOADING = 'LOGIN_LOADING';
export function loginLoading () {
    return {type: LOGIN_LOADING};
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export function login (data) {
    return {type: LOGIN_SUCCESS, data};
}

export const LOGIN_REFRESH = 'LOGIN_REFRESH';
export function loginRefresh (data) {
    return {type: LOGIN_REFRESH, data};
}

export const LOGIN_ERROR = 'LOGIN_ERROR';
export function logError (statusText) {
    return {type: LOGIN_ERROR, statusText};
}

export const LOGOUT = 'LOGOUT';
export function logout () {
    return {type: LOGOUT};
}

export function performLogin(fields) {
    return dispatch => {

        dispatch(loginLoading());

        API.post('login_check', fields)
            .then(res => {
                dispatch(login(res.data));
            })
            .catch((error) => {
                if(error.response) {
                    dispatch(logError(error.response.data.message));
                }
            })
    }
}
