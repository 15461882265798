import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import RestaurantEdit from "./RestaurantEdit";
import MenuEdit from "./MenuEdit";
import ImageEdit from "./ImageEdit";
import { getUrlFromPath } from '../../utils/Utils';
import { ApiDataClearError, performApiDataRequest } from '../../actions/ApiData';
import ErrorMessage  from '../General/ErrorMessage';
import { connect } from 'react-redux';

class RestaurantEditWrapper extends React.Component {
    state = {
        activeItem: 'data',
        isLoading: true,
        new: !getUrlFromPath(this.props.location.pathname, 2),
        error: false,
        errorMessage: ''
    }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    confirmErrorMessage = () => {
        this.setState({ error: false, errorMessage: ''})
        this.props.clearErrorMessage()
    }

    componentDidMount() {
        if(!this.state.new) {
             this.props.performRestaurantEditRequest('restaurants/'+getUrlFromPath(this.props.location.pathname,2));
        }

        if(typeof this.props.apiData.data.Towns !== "undefined") {
            this.setState({isLoading: false})
        }
    };

    componentDidUpdate(prevProps) {
        if(typeof prevProps.apiData.data.Towns === "undefined" && typeof this.props.apiData.data.Towns !== "undefined") {
            this.setState({isLoading: false})
        }

        if(prevProps.apiData.refreshUrl !== 'restaurants' && this.props.apiData.refreshUrl === 'restaurants') {
            this.props.history.push('/restaurants');
        }

        if(!prevProps.apiData.error  && this.props.apiData.error) {
            this.setState({error: true, errorMessage: this.props.apiData.errorMessage })
        }
    }

    renderActiveSwitch(active) {
        switch(active) {
            case 'image':
                return (<ImageEdit location={this.props.location}/>);
            case 'menu':
                return (<MenuEdit location={this.props.location}/>);
            default:
                return (<RestaurantEdit history={this.props.history} new={this.state.new} restaurant={this.props.apiData.data.Restaurants} location={this.props.location}/>);
        }
    }

    render() {
        const { activeItem, error, errorMessage } = this.state
        return (
            <div className='edit-restaurant'>
                <ErrorMessage error={error} errorMessage={errorMessage} closeErrorMessage={this.confirmErrorMessage}/>
                <Container className={'input-form'}>
                    <Menu attached='top' pointing secondary>
                        <Menu.Item
                            name='data'
                            active={activeItem === 'data'}
                            color='teal'
                            disabled={this.state.isLoading}
                            onClick={this.handleItemClick}
                        >Edit restaurant data</Menu.Item>
                        <Menu.Item
                            name='menu'
                            active={activeItem === 'menu'}
                            color='teal'
                            disabled={this.state.isLoading || this.state.new}
                            onClick={this.handleItemClick}
                        >Edit Menu</Menu.Item>
                        <Menu.Item
                            name='image'
                            active={activeItem === 'image'}
                            color='teal'
                            disabled={this.state.isLoading || this.state.new}
                            onClick={this.handleItemClick}
                        >Edit Images</Menu.Item>
                    </Menu>
                    {
                        this.renderActiveSwitch(activeItem)
                    }
                </Container>
            </div>
        )
    }
}

const mapStateRestaurantEditWrapper = (state) => {
    return {
        apiData: state.apiData,
        role: state.userData.role
    }
};

const mapDispatchRestaurantEditWrapper = (dispatch) => (
    {
        performRestaurantEditRequest: (url) => (
            dispatch(performApiDataRequest(url))
        ),
        clearErrorMessage: () => (
            dispatch(ApiDataClearError())
        ),
    }
);

const RestaurantEditWrapperDisplay = connect(
    mapStateRestaurantEditWrapper,
    mapDispatchRestaurantEditWrapper
)(RestaurantEditWrapper);

export default RestaurantEditWrapperDisplay;
