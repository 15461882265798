import React from 'react';
import { connect } from 'react-redux';
import { filterDishes } from '../../utils/Utils';
import MenuShowCard from './MenuShowCard';
import MenuEditCard from './MenuEditCard';
import { performApiDataRequest } from '../../actions/ApiData';
import { Header, Loader, Segment } from 'semantic-ui-react';

class RestaurantMenuEdit extends React.Component {
    state = {
        isLoading: false,
        dishCategories: this.props.apiData.data.Restaurants.dishCategories,
        openedItems: []
    };

    openEditMenu = (key, value) => {
        const id = value.value;
        this.setState({ openedItems: this.state['openedItems'].concat(id) });
    };

    closeEditMenu = (key, value) => {
        const id = value.value;
        this.setState({ openedItems: this.state['openedItems'].filter(filter => filter !== id), isLoading: true });
    };

    componentDidUpdate (prevProps) {
        if (JSON.stringify(prevProps.apiData.data.Restaurants.dishCategories) !== JSON.stringify(this.props.apiData.data.Restaurants.dishCategories)) {
            const dishCategories = this.props.apiData.data.Restaurants.dishCategories;
            let isLoading = false;
            this.setState({
                dishCategories, isLoading
            });
        }
    }

    // Filter dish categories and dishes - API Platform cant filter it
    filterDishes =() => {
        let dishCategories = this.state.dishCategories;
        let filtereddishCategories = {}
        Object.keys(dishCategories).map((key) => {
            Object.keys(dishCategories[key]['dishes']).map((keyy) => {
                if(dishCategories[key]['dishes'][keyy]['status'] !== 1) {
                    delete dishCategories[key]['dishes'][keyy]
                }
            })
            if(dishCategories[key]['status'] !== 1) {
                delete dishCategories[key]
            }
        })
        return dishCategories
    }

    render () {
        let dishCategories = filterDishes(this.state.dishCategories)

        return (
            <div className='edit-restaurant'>
                <Header as='h1'
                        content={'Edit the menus of ' + this.props.apiData.data.Restaurants.name}
                        textAlign='center'/>
                <Segment.Group>
                    {
                        this.state.isLoading ? (<Loader active/>) : (
                            <div> {
                                Object.keys(dishCategories).map((key) => (
                                    <Segment className='category' key={dishCategories[key]['@id']}>
                                        {
                                            this.state.openedItems.includes(dishCategories[key]['@id']) ? (
                                                <MenuEditCard closeEditMenu={this.closeEditMenu}
                                                              action='put'
                                                              type='category'
                                                              item={dishCategories[key]}/>
                                            ) : (
                                                <MenuShowCard openEditMenu={this.openEditMenu}
                                                              item={dishCategories[key]}/>
                                            )
                                        }
                                        {
                                            Object.keys(dishCategories[key]['dishes']).map((keyy) => (
                                                <Segment key={dishCategories[key]['dishes'][keyy]['@id']}>
                                                    {
                                                        this.state.openedItems.includes(dishCategories[key]['dishes'][keyy]['@id']) ? (
                                                            <MenuEditCard closeEditMenu={this.closeEditMenu}
                                                                          action='put'
                                                                          type='dish'
                                                                          item={dishCategories[key]['dishes'][keyy]}/>
                                                        ) : (
                                                            <MenuShowCard openEditMenu={this.openEditMenu}
                                                                          class='dish'
                                                                          item={dishCategories[key]['dishes'][keyy]}/>
                                                        )
                                                    }
                                                </Segment>
                                            ))
                                        }
                                        <Segment key={'newDishFor' + dishCategories[key]['@id']}>
                                            <span>Save new dish for '{dishCategories[key]['name']}': </span>
                                            <MenuEditCard closeEditMenu={this.closeEditMenu}
                                                          action='post'
                                                          type='dish'
                                                          category={dishCategories[key]['@id']}
                                            />
                                        </Segment>
                                    </Segment>
                                ))}
                                <Segment key={'newCategoryFor'}>
                                    <span>Save new category for '{this.props.apiData.data.Restaurants.name}': </span>
                                    <MenuEditCard closeEditMenu={this.closeEditMenu}
                                                  action='post'
                                                  type='category'
                                                  class='category'
                                    />
                                </Segment>
                            </div>
                        )
                    }
                </Segment.Group>

            </div>
        );
    }
}

const mapStateRestaurantMenuEdit = (state) => {
    return {
        apiData: state.apiData,
        role: state.userData.role
    };
};

const mapDispatchRestaurantMenuEdit = (dispatch) => (
    {
        performRestaurantMenuRequest: (url) => (
            dispatch(performApiDataRequest(url))
        )
    }
);

const RestaurantMenuEditDisplay = connect(
    mapStateRestaurantMenuEdit,
    mapDispatchRestaurantMenuEdit
)(RestaurantMenuEdit);

export default RestaurantMenuEditDisplay;
